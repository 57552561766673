import React, {useEffect, useState} from 'react';
import {Line} from "react-chartjs-2";
import { Chart as ChartJS, registerables } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import {useMediaQuery} from "@mui/material";
import { red } from '@mui/material/colors';
import { green } from '@mui/material/colors';
import { alpha } from "@mui/material";


const CustomLineChart = ({chartData}) => {
    ChartJS.register(...registerables)
    ChartJS.register(zoomPlugin)

    const [chartBorderColor, setChartBorderColor] = useState(red[500])
    const [chartBackgroundColor, setChartBackgroundColor] = useState(red[300])

    const chartOptions = {
        responsive:true,
        maintainAspectRatio:false,
        scales:{y:{ticks:{stepSize:0.5}}},
        plugins: {
            zoom: {
                zoom: {
                    wheel: {enabled: true},
                    pinch: {enabled: true},
                    mode: 'x',
                },
                pan: {
                    enabled: true,
                    mode: 'x',
                },
            }
        }
    }

    useEffect(() => {
            if(ChartJS.instances['0'] !== undefined){
                ChartJS.instances['0'].resetZoom()
            }

            setChartBorderColor(chartData.datasets[0].data.slice(-1) > chartData.datasets[0].data[0] ? green[500] : red[500])
            setChartBackgroundColor(chartData.datasets[0].data.slice(-1) > chartData.datasets[0].data[0] ? alpha(green[200], 0.35) : alpha(red[200], 0.35))
    }, [chartData]);

    const upMDScreenSize = useMediaQuery((theme) => theme.breakpoints.up('md'));

    console.log(chartData)

    return (
        <div style={{position:'relative', width:'100%', height: upMDScreenSize ? '50vh' : '40vh'}}>
            <Line
                style={{height: 'auto', width: 'auto'}}
                data={{
                    ...chartData,
                    datasets: [{
                        ...chartData.datasets['0'],
                        fill:true,
                        borderColor: chartBorderColor,
                        backgroundColor: chartBackgroundColor,
                        pointBorderWidth:0,
                        pointBackgroundColor:'rgba(0,0,0,0)',
                    }]
                }}
                options={chartOptions}
            />
        </div>
    );
};

export default CustomLineChart;