import React from 'react';
import {Box, LinearProgress} from "@mui/material";

const LoadingWrapper = ({children, isLoading, flex=1}) => {

    return (
        <Box sx={{flex:flex}}>
            {
                !isLoading ?
                    children
                :
                    <Box sx={{my:10, display:'flex', alignItems:'center', justifyContent:'center'}}>
                        <LinearProgress sx={{flex:0.5}}/>
                    </Box>
            }
        </Box>
    );
};

export default LoadingWrapper;