import React from 'react';
import {List, ListItem, Stack, Typography} from "@mui/material";

const LegalDisclaimer = () => {
    return (
        <Stack gap={2}>
            <Typography variant='h2' fontWeight='bold'>Legal disclaimer</Typography>
            <Typography>This platform has been developed for <strong>PERSONAL PURPOSES</strong> and is not intended for commercial profit. The primary goal of platform is to provide the author a learning and research environment. The features, contents, and services offered on this platform are designed to support skill development activities and foster intellectual exploration.</Typography>
            <Typography>The author of this platform explicitly disclaims any ownership of the data processed through this system. Consequently, it absolves itself of any responsibility for the subsequent utilization and compliance with data rights by end-users.</Typography>
            <Typography>The collection of data provided on this platform is for <strong>INFORMATIONAL PURPOSES ONLY</strong> and should not be construed as personal financial advice. Therefore, it should not be considered a substitute for professional financial advice tailored to your individual circumstances.</Typography>
            <Typography>As user of the platform you acknowledge and agree that the author shall not be held liable for any losses or damages incurred as a result of reliance on the information provided herein. You are solely responsible for your own financial decisions and should exercise them with caution and diligence.</Typography>
            <Typography>Regarding the origin of the information:</Typography>
            <List sx={{ listStyleType: 'disc', listStylePosition: 'inside' }}>
                <ListItem sx={{ display: 'list-item' }}>Bond fundamental information and related history records are property of Bolsa Nacional de Valores S.A.</ListItem>
                <ListItem sx={{ display: 'list-item' }}>All Costa Rica's indicator, yield curve and index data is property of Banco Central de Costa Rica.</ListItem>
                <ListItem sx={{ display: 'list-item' }}>Basic country data is property of REST Countries API service.</ListItem>
                <ListItem sx={{ display: 'list-item' }}>Macroeconomic country data is property of The World Bank.</ListItem>
            </List>
            <Typography>The software is provided "as is", without warranty of any kind, express or implied, including but not limited to the warranties of merchantability, fitness for a particular purpose and noninfringement. In no event shall the authors be liable for any claim, damages or other liability, whether in an action of contract, tort or otherwise, arising from, out of or in connection with the software or the use or other dealings in the software.</Typography>
            <Typography>By accessing and using this platform, you acknowledge and agree to abide by these terms. If you do not agree with these terms, please refrain from using the platform. If you have any doubts or questions, please contact the author at <a href={'mailto::amonterog@estudiantec.cr'}>amonterog@estudiantec.cr</a> or <a href={'mailto::andresmonterog@hotmail.com'}>andresmonterog@hotmail.com</a>.</Typography>
        </Stack>
    );
};

export default LegalDisclaimer;