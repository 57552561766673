const dateFormatter = (date, type)  => {
    const longOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        timeZone: 'UTC',
    };
    const shortOptions = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        timeZone: 'UTC',
    };
    return date.toLocaleDateString('en-US', type === 'long' ? longOptions : shortOptions)
}

const timeFormatter = (date)  => {
    return date.toLocaleTimeString('es-ES', {hour: "2-digit", minute: "2-digit"})
}

const capitalizeFirstLetterFormatter = (word) => {
    return word[0].toUpperCase() + word.slice(1);
}

const currencySymbolFormatter = (currency) => {
    switch (currency){
        case 'Dollars':
            return "$"
        case 'Colones':
            return '₡'
        default:
            return currency
    }
}

const currencyAmountFormatter = (currency, amount) => {
    if(amount){
        switch (currency){
            case 'Dollars':
                return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount)
            case 'Colones':
                return new Intl.NumberFormat('es-CR', { style: 'currency', currency: 'CRC' }).format(amount)
            default:
                return currency
        }
    }
    return "Not provided"
}

const booleanDataPointFormatter = (point) => {
    if(point === true || point === "true" || point === 1){
        return "Yes"
    }else if(point === false || point === "false" || point === 0){
        return "No"
    }else{
        return "Not provided"
    }
}

const listFormatter = (list) => {
    if (list.length){
        return (new Intl.ListFormat('en')).format(list)
    }
    return "Not provided"
}

const amountFormatter = (amount, style=undefined, unit=undefined) => {
    if (amount){
        return new Intl.NumberFormat("en-US", {
            style,
            unit,
            minimumFractionDigits: style === "percent" ? 2 : undefined,
        }).format(amount);
    }
    return "Not provided"
}




const timeRangeFormatter = (range) => {
    switch(range){
        case ('5D'):
            return "in the last 5 days"
        case ('1M'):
            return "in the last month"
        case ('6M'):
            return "in the last 6 months"
        case ('YTD'):
            return "year to date"
        case ('1Y'):
            return "in the last year"
        case ('5Y'):
            return "in the last 5 years"
        case ('MAX'):
            return "in all recorded history"
        default:
            return "in all recorded history"
    }
}

const tendencyFormatter = (variation) => {
    if (variation > 0.001) {
        return "up"
    } else if (variation < -0.001){
        return "down"
    }else{
        return "flat"
    }
}

const bondNameFormatter = (bond) => {
    return `${bond.country} ${bond.issuer} ${bond.rate.toFixed(2)}% ${dateFormatter(bond.maturity, "short")}`
}

export{
    dateFormatter,
    timeFormatter,
    capitalizeFirstLetterFormatter,
    currencySymbolFormatter,
    currencyAmountFormatter,
    timeRangeFormatter,
    tendencyFormatter,
    booleanDataPointFormatter,
    listFormatter,
    amountFormatter,
    bondNameFormatter,
}