import React from 'react';
import {useNavigate} from "react-router-dom";
import {Button, Stack, Typography} from "@mui/material";

const Error = ({ code, description }) => {
    const navigate = useNavigate();

    return (
        <Stack
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "100vh",
                px: { lg: 20, md: 10, xs: 5 },
            }}
            gap={2}
        >
            <Typography textAlign="center" variant="h3" color="primary.main">
                ERROR {code}
            </Typography>
            <Typography textAlign="center" color="text.secondary">
                {description}
            </Typography>
            <Button onClick={() => navigate("/")} variant="contained">
                GO BACK HOME
            </Button>
        </Stack>
    );
};

export default Error;