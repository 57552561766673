import React from 'react';
import {IconButton, Stack, Typography} from "@mui/material";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

const SearchPagination = ({page, onChangePageHandler, isThereNextResultsPage}) => {
    return (
        <Stack direction="row" gap={2} alignItems="center" justifyContent="center">
            <IconButton disabled={page === 1} onClick={() => onChangePageHandler(page > 1 ? page - 1 : 0)}>
                <KeyboardArrowLeftIcon/>
            </IconButton>
            <Typography>{page}</Typography>
            <IconButton disabled={!isThereNextResultsPage} onClick={() => onChangePageHandler(page + 1 )}>
                <KeyboardArrowRightIcon/>
            </IconButton>
        </Stack>
    );
};

export default SearchPagination;