import React, {useContext, useEffect, useState} from 'react';
import {useSearchParams} from "react-router-dom";
import {Box, Stack, useMediaQuery} from "@mui/material";
import SearchBar from "../../components/searchBar/SearchBar";
import {getAllInstruments} from "../../api/app/instrumentRequests";
import Context from "../../context/Context";
import LoadingWrapper from "../../components/LoadingWrapper";
import BondsTable from "./components/BondsTable";
import BondGrid from "./components/BondGrid";
import NoResults from "../../components/NoResults";
import FilterBar from "./components/filterBar/FilterBar";
import SearchPagination from "./components/SearchPagination";
import CompoundHeader from "../../components/CompoundHeader";

const Bonds = () => {

    const [searchParams, setSearchParams] = useSearchParams({
        searchTerm: encodeURIComponent(""),
        page: encodeURIComponent(1),
        retrieveAll: encodeURIComponent(false),
        sortParam: encodeURIComponent(null),
        descSort: encodeURIComponent(true)
    });

    const resultsPerPage = 25;

    const upLGScreenSize = useMediaQuery((theme) => theme.breakpoints.up('lg'));

    const {showSnackbar} = useContext(Context);

    const [results, setResults] = useState({})
    const [isThereNextResultsPage, setIsThereNextResultsPage] = useState(false)
    const [localSearchTerm, setLocalSearchTerm] = useState(decodeURIComponent(searchParams.get("searchTerm")) || "")
    const [currentPage, setCurrentPage] = useState(1)
    const [areResultsLoading, setAreResultsLoading] = useState(true)

    const onSubmitSearchBarHandler = () => {
        onChangeSearchTermHandler(localSearchTerm)
        retrieveResultsPage(1)
    }

    const onChangeSearchParamHandler = (key, value) => {
        setSearchParams(params => {
            params.set(key, encodeURIComponent(value));
            return params;
        });
    }

    const onChangeSearchTermHandler = (searchTerm) => {
        onChangeSearchParamHandler("searchTerm", searchTerm)
    }

    const onChangePageHandler = (page) => {
        onChangeSearchParamHandler("page", page)
    }

    const retrieveResultsPage = (page) => {

        setAreResultsLoading(true)

        const lastRetrieveResultIndex = (page - 1) * (resultsPerPage - 1)

        getAllInstruments(
            lastRetrieveResultIndex,
            resultsPerPage,
            decodeURIComponent(searchParams.get("retrieveAll")),
            decodeURIComponent(searchParams.get("searchTerm")),
            decodeURIComponent(searchParams.get("sortParam")),
            decodeURIComponent(searchParams.get("descSort"))
        ).then((searchResult) => {
            setResults({[page]:searchResult})
            setIsThereNextResultsPage(searchResult.length === resultsPerPage)
            setAreResultsLoading(false)
        }).catch(e => {
            showSnackbar(e.message, 'warning');
        })
    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if(localSearchTerm !== decodeURIComponent(searchParams.get("searchTerm"))){
                onChangeSearchTermHandler(localSearchTerm)
            }
        }, 500)
        return () => clearTimeout(delayDebounceFn)
    }, [localSearchTerm])

    useEffect(() => {

        const urlParamPage = decodeURIComponent(searchParams.get("page"))

        if(parseInt(urlParamPage) !== currentPage){
            setCurrentPage(parseInt(urlParamPage))
            if(!results[urlParamPage]){
                retrieveResultsPage(parseInt(urlParamPage))
            }
        }else{
            onChangeSearchParamHandler("page", 1)
            retrieveResultsPage(parseInt(urlParamPage))
        }
    }, [searchParams])

    return (
        <Stack gap={6}>
            <CompoundHeader title="Bonds" subtitle="Search and filter by maturity, coupon, price or status"/>
            <Box sx={{width:{xs:"100%", md:"33%"}}}>
                <SearchBar
                    performSearch={onSubmitSearchBarHandler}
                    searchBarValue={localSearchTerm}
                    searchBarChangeHandler={setLocalSearchTerm}
                />
            </Box>


            <FilterBar filters={{
                retrieveAll: decodeURIComponent(searchParams.get("retrieveAll")) === "true",
                sortParam: decodeURIComponent(searchParams.get("sortParam")),
                descSort: decodeURIComponent(searchParams.get("descSort")) === "true"
            }} onChangeFilterHandler={onChangeSearchParamHandler}/>
            <LoadingWrapper isLoading={areResultsLoading}>
                {
                    results[searchParams.get("page")] && results[searchParams.get("page")].length > 0 && !areResultsLoading?
                        upLGScreenSize ?
                            <BondsTable bonds={results[searchParams.get("page")]}/>
                            :
                            <BondGrid bonds={results[searchParams.get("page")]}/>
                        :
                        <NoResults/>
                }
            </LoadingWrapper>
            <SearchPagination page={parseInt(searchParams.get("page"))} onChangePageHandler={onChangePageHandler} isThereNextResultsPage={isThereNextResultsPage}/>
        </Stack>

    );
};

export default Bonds;