import React from 'react';
import { Stack } from "@mui/material";

const ResponsiveLayout = ({children}) => {
    return (
        <Stack direction={{xs:"column", lg:"row"}} sx={{ml: {lg:0}}} gap={2}>
            {children}
        </Stack>
    );
};

export default ResponsiveLayout;