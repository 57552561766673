import React from 'react';
import {Card, CardActionArea, CardContent, Stack, Typography} from "@mui/material";
import {dateFormatter} from "../../util/formatterUtil";
import {useNavigate} from "react-router-dom";

const FeaturedBondCard = ({bond}) => {

    const navigate = useNavigate()
    return (
        <Card>
            <CardActionArea onClick={() => navigate('/bond/' + bond.isin)}>
                <CardContent>
                    <Stack>
                        <Typography color="primary">{bond.country} {bond.issuer} {bond.rate.toFixed(2)}% {dateFormatter(bond.maturity, "short")}</Typography>
                        <Typography color="text.secondary">{bond.isin}</Typography>
                    </Stack>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default FeaturedBondCard;