import React from 'react';
import {Stack} from "@mui/material";
import CompoundHeader from "../../components/CompoundHeader";

const PortfolioCalculator = () => {
    return (
        <Stack gap={8}>
            <CompoundHeader title="Portfolio calculator" subtitle="Analytical instrument for portafolio valuation"/>
        </Stack>
    );
};

export default PortfolioCalculator;