import React from 'react';
import {Stack, Typography} from "@mui/material";

const CompoundSubHeader = ({subheader, caption}) => {
    return (
        <Stack>
            <Typography variant='subtitle1' fontWeight='bold'>{subheader}</Typography>
            <Typography color="text.secondary">{caption}</Typography>
        </Stack>
    );
};

export default CompoundSubHeader;