import React, {useEffect, useState} from 'react';
import {Stack, Divider, Typography, useMediaQuery} from "@mui/material";
import OrderFilterChip from "./OrderFilterChip";
import ToggleFilterChip from "./ToggleFilterChip";

const FilterBar = ({filters, onChangeFilterHandler}) => {

    const upMDScreenSize = useMediaQuery((theme) => theme.breakpoints.up('md'));

    const [localFilters, setLocalFilters] = useState({

    })

    useEffect(() => {
        setLocalFilters({
            retrieveAll: filters.retrieveAll,
            sortParam: filters.sortParam,
            descSort: filters.descSort
        })
    }, [filters]);

    const sortParamToggleHandler = (sortParam) => {
        sortParam = filters.sortParam === null || filters.sortParam !== sortParam || (filters.sortParam === sortParam && filters.descSort) ? sortParam :  null
        const descSort = sortParam === filters.sortParam ? !filters.descSort : true
        onChangeFilterHandler("sortParam", sortParam)
        onChangeFilterHandler("descSort", descSort)
    }


    return (
        <Stack direction={upMDScreenSize ? "row" : "column"} gap={2} alignItems={upMDScreenSize ? "center" : "flex-start"}>
            <Typography fontWeight='bold'>Sort by</Typography>
            <Stack direction="row" gap={2} alignItems='center'>
                <OrderFilterChip label="maturity" value="maturity" currentFilter={filters.sortParam} isDescSort={filters.descSort} toggleHandler={sortParamToggleHandler}/>
                <OrderFilterChip label="coupon" value="rate" currentFilter={filters.sortParam} isDescSort={filters.descSort} toggleHandler={sortParamToggleHandler}/>
                <OrderFilterChip label="price" value="price" currentFilter={filters.sortParam} isDescSort={filters.descSort} toggleHandler={sortParamToggleHandler}/>
            </Stack>
            <Divider orientation="vertical" flexItem/>
            <ToggleFilterChip label="Show only active bonds" isSelected={!filters.retrieveAll} toggleHandler={() => onChangeFilterHandler("retrieveAll", !filters.retrieveAll)}/>
        </Stack>

    );
};

export default FilterBar;