import React from 'react';
import {Box, Divider, IconButton, Stack, Typography, useMediaQuery} from "@mui/material";
import BondStatusChip from "../../../components/BondStatusChip";
import {bondNameFormatter, dateFormatter} from "../../../util/formatterUtil";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const CompoundHeader = ({bondDetail}) => {

    const upLGScreenSize = useMediaQuery((theme) => theme.breakpoints.up('lg'));

    return (
        <Box>
            {
                bondDetail &&
                <Stack gap={2} alignItems='flex-start'>
                    <Typography variant={upLGScreenSize ? 'h3' : 'h4'} sx={{fontWeight: 'bold'}}>{bondNameFormatter(bondDetail)}</Typography>
                    <Stack direction="row" gap={2} alignItems="center">
                        {bondDetail && <BondStatusChip status={bondDetail.status} maturity={bondDetail.maturity} chipSize={upLGScreenSize ? 'medium' : 'small'}/>}
                        <Divider flexItem orientation="vertical"/>
                        <Stack direction="row" gap={1} alignItems="center">
                            <Typography variant={upLGScreenSize ? 'h5' : 'p'}>{bondDetail.isin}</Typography>
                            <IconButton size="small" color="primary" onClick={() => navigator.clipboard.writeText(bondDetail.isin)}><ContentCopyIcon fontSize="inherit"/></IconButton>
                        </Stack>
                    </Stack>
                </Stack>
            }
        </Box>


    );
};

export default CompoundHeader;