import React, {useEffect, useState} from 'react';
import {Card, CardActionArea, CardContent, Stack, Typography} from "@mui/material";
import {capitalizeFirstLetterFormatter, tendencyFormatter} from "../../util/formatterUtil";
import TendencyBadge from "../TendencyBadge";

const IndicatorCard = ({title, indicator, onClickHandler, data, flex="initial"}) => {

    const [tendency, setTendency] = useState("up")

    useEffect(() => {
        if(data){
            setTendency(tendencyFormatter(data.delta))
        }
    }, [data]);

    return (
        <Card sx={{flex:flex}}>
            <CardActionArea onClick={() => onClickHandler(indicator)} sx={{display:"flex", minHeight:"100%", alignItems:"flex-start"}}>
                <CardContent sx={{flex:1}}>
                    <Stack gap={2}>
                        <TendencyBadge tendency={tendency} variation={data.delta * 100}/>
                        <Stack>
                            <Typography variant="h4">{data.target.value.toFixed(2)}%</Typography>
                            <Typography color="text.secondary">{capitalizeFirstLetterFormatter(title)}</Typography>
                        </Stack>
                    </Stack>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default IndicatorCard;