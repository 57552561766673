import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#0023a5',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#ffffff',
            contrastText: '#0023a5',
        },
    },
    typography:{
        subtitle1:{
            fontSize:"1.25em"
        }
    },
});

theme.components = {
    MuiButton: {
        defaultProps : {
            disableElevation: true,
        },
        styleOverrides: {
            root: {
                textTransform:'none',
                fontWeight:400,
                fontSize:'1rem',
                transition: "background 0.25s, color 0.75s",
            },
            contained:{
                border:"1px solid",
                borderColor:"#0023a5",
                "&:hover": {
                    background:"transparent",
                    border:"1px solid",
                    borderColor:"#0023a5",
                    color:"#0023a5"
                }
            },
            containedSecondary:{
                border:"1px solid",
                borderColor:"#ffffff",
                "&:hover": {
                    background:"transparent",
                    border:"1px solid",
                    borderColor:"#ffffff",
                    color:"#ffffff"
                }
            }
        },
    },
    MuiPaper:{
        defaultProps : {
            variant: "outlined",
        }
    },
    MuiToggleButton:{
        styleOverrides: {
            root: {
                textTransform:'none',
                fontWeight:400,
                fontSize:'1rem'
            },
        },
    }
};

export default theme;