import React from 'react';
import CompoundHeader from "../../components/CompoundHeader";
import {Stack} from "@mui/material";

const IndicatorsIndexes = () => {
    return (
        <Stack gap={8}>
            <CompoundHeader title="Indicators & Indexes" subtitle="Costa Rica's financial reference dashboard"/>
        </Stack>
    );
};

export default IndicatorsIndexes;