import React from 'react';
import {Box, Card, CardContent, Stack, Typography, useMediaQuery} from "@mui/material";
import DataCard from "../../../components/DataCard";

const HighlightsCard = ({highlights}) => {

    const upLGScreenSize = useMediaQuery((theme) => theme.breakpoints.up('lg'));

    return (
        <Stack direction={{xs:"column", lg:"row"}} sx={{ml: {xs:- 2, lg:0}}} gap={2}>
            {
                upLGScreenSize ?
                    highlights.map(highlight => {
                        return(
                            <Box sx={{flex:{xs:1, lg:0.25}}}>
                                <Card>
                                    <CardContent>
                                        <Typography variant='h4' fontWeight='bold'>{highlight.value}</Typography>
                                        <Typography color="text.secondary">{highlight.label}</Typography>
                                    </CardContent>
                                </Card>
                            </Box>
                        )
                    })
                :
                    <DataCard title="Bond highlights" data={highlights}/>
            }
        </Stack>

    );
};

export default HighlightsCard;