import React from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import {Error} from "./routes/error";
import RouteWrapper from "./routes/RouteWrapper";
import Dashboard from "./routes/dashboard/Dashboard";
import Bond from "./routes/bond/Bond";
import LegalDisclaimer from "./routes/legalDisclaimer/LegalDisclaimer";
import IndicatorsIndexes from "./routes/indicatorsIndexes/IndicatorsIndexes";
import Bonds from "./routes/bonds/Bonds";
import Country from "./routes/country/Country";
import PortfolioCalculator from "./routes/portfolioCalculator/PortfolioCalculator";
import Maintenance from "./routes/maintenance/Maintenance";

function App() {

    const maintenance = false;

    return (
        <BrowserRouter>
            <Routes>
                {
                    !maintenance ?
                        <Route element={<RouteWrapper/>}>
                            <Route  path='/' element={<Dashboard/>}/>
                            <Route  path='/bond/:id' element={<Bond/>}/>
                            <Route  path='/bonds/' element={<Bonds/>}/>
                            <Route  path='/country/:id' element={<Country/>}/>
                            <Route  path='/indicators-indexes' element={<IndicatorsIndexes/>}/>
                            <Route  path='/portfolio-calculator' element={<PortfolioCalculator/>}/>
                            <Route  path='/legal-disclaimer' element={<LegalDisclaimer/>}/>
                        </Route>
                    :
                        <Route  path='/' element={<Maintenance/>}/>
                }

                <Route path='*' element={<Error code={404} description="Oh no! The requested page does not exist."/>}/>
            </Routes>
        </BrowserRouter>
    );
}
export default App;