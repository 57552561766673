import React from 'react';
import SearchIcon from "@mui/icons-material/Search";
import {IconButton, InputAdornment, TextField} from "@mui/material";
import {useNavigate} from "react-router-dom";

const SearchInput = ({params, highlightedResult, onSubmitHandler}) => {

    const navigate = useNavigate();


    return (
        <TextField
            {...params}
            placeholder="ISIN, issuer or issuer country "
            onKeyDown={(e) => {
                if(e.code === 'Enter' && highlightedResult){
                    navigate('/bond/' + highlightedResult.isin)
                }}}
            variant='standard'
            InputProps={{
                ...params.InputProps,
                type: 'search',
                startAdornment: (
                    <InputAdornment sx={{display:{xs:'none', md:'flex'}}} position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment sx={{display:{xs:'flex', md:'none'}}} position="end">
                        <IconButton
                            onClick={onSubmitHandler}
                            variant='contained'
                            color="primary"
                        >
                            <SearchIcon />
                        </IconButton>
                    </InputAdornment>
                ),
                disableUnderline: true,
                style: { minHeight:60, paddingInline:30 },
            }}
        />
    );
};

export default SearchInput;