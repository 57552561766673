import React, {useContext, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import Context from "../../context/Context";
import {getCountryDataRequest, getEconomicAndGrowthIndicatorsRequest} from "../../api/country/issuerCountryRequests";
import {
    booleanDataPointFormatter,
    listFormatter,
    amountFormatter,
    currencyAmountFormatter
} from "../../util/formatterUtil";
import {Box, Stack} from "@mui/material";
import LoadingWrapper from "../../components/LoadingWrapper";
import DataCard from "../../components/DataCard";
import CompoundHeader from "./components/CompoundHeader";
import {getAllInstruments, instrumentSearchSuggestions} from "../../api/app/instrumentRequests";
import FeaturedBonds from "../../components/FeaturedBonds";
import SimpleInstrumentGroup from "../../components/SimpleInstrumentGroup";

const Country = () => {

    const {id} = useParams()
    const {showSnackbar, setIsLoading} = useContext(Context)

    const [basicData, setBasicData] = useState(null)
    const [basicDataDisplay, setBasicDataDisplay] = useState([])
    const [economyAndGrowthData, setEconomyAndGrowthData] = useState(null)
    const [economyAndGrowthDataDisplay, setEconomyAndGrowthDataDisplay] = useState([])
    const [relatedBonds, setRelatedBonds] = useState([])

    const clearState = () => {
        setBasicData(null)
        setEconomyAndGrowthData(null)
    }

    useEffect(() => {
        setIsLoading(true)
        clearState()
        getCountryDataRequest(id).then(data => setBasicData(data)).catch(e => {
            showSnackbar(e.message, 'warning');
        })
        getAllInstruments(0, 3, false, id, "rate", true).then(instruments => { setRelatedBonds(instruments) }).catch(e => {
            showSnackbar(e.message, 'warning');
        })
        getEconomicAndGrowthIndicatorsRequest(id).then(data => setEconomyAndGrowthData(data)).catch(e => {
            showSnackbar(e.message, 'warning');
        })
    }, [id]);

    useEffect(() => {
        if(basicData && economyAndGrowthData){
            setBasicDataDisplay([
                {label: 'Official name', value: basicData.officialName},
                {label: 'Capital', value: basicData.capital},
                {label: 'Region', value: basicData.region},
                {label: 'Subregion', value: basicData.subregion},
                {label: 'Borders', value:listFormatter(basicData.borders)},
                {label: 'Languages', value: listFormatter(basicData.languages)},
                {label: 'Currencies', value: listFormatter(basicData.currencies)},
                {label: 'Is independent', value: booleanDataPointFormatter(basicData.isIndependent)},
                {label: 'Area', value: amountFormatter(basicData.area, "unit", "meter") + "²"},
                {label: 'Population', value: amountFormatter(basicData.population, "decimal")},
            ])
            setEconomyAndGrowthDataDisplay([
                {label: economyAndGrowthData.gdp.label, value: currencyAmountFormatter("Dollars", economyAndGrowthData.gdp.value)},
                {label: economyAndGrowthData.gdpGrowth.label, value: amountFormatter(economyAndGrowthData.gdpGrowth.value/100, "percent")},
                {label: economyAndGrowthData.gdpPerCapita.label, value: currencyAmountFormatter("Dollars", economyAndGrowthData.gdpPerCapita.value)},
                {label: economyAndGrowthData.grossCapitalFormation.label, value: amountFormatter(economyAndGrowthData.grossCapitalFormation.value/100, "percent")},
                {label: economyAndGrowthData.gdpExports.label, value: amountFormatter(economyAndGrowthData.gdpExports.value/100, "percent")},
                {label: economyAndGrowthData.gdpImports.label, value: amountFormatter( economyAndGrowthData.gdpImports.value/100, "percent")},
                {label: economyAndGrowthData.accountBalance.label, value: amountFormatter( economyAndGrowthData.accountBalance.value/100, "percent")},
            ])
            setIsLoading(false)
        }
    }, [basicData, economyAndGrowthData]);

    return (
        <LoadingWrapper>
            <Stack gap={4} sx={{mt:8}}>
                {basicData && <CompoundHeader countryName={basicData.officialName} countryFlag={basicData.flag}/>}
                <Stack direction={{xs:"column", lg:"row"}} sx={{ml: {xs:- 2, lg:0}}} gap={2}>
                    <Box sx={{flex:{xs:1, lg:0.5}}}>
                        <DataCard title="General information" data={basicDataDisplay}/>
                    </Box>
                    <Box sx={{flex:{xs:1, lg:0.5}}}>
                        <DataCard title="Economy & Growth" data={economyAndGrowthDataDisplay}/>
                    </Box>
                </Stack>
                <SimpleInstrumentGroup title="Related bonds" bonds={relatedBonds}/>
            </Stack>
        </LoadingWrapper>
    );
};

export default Country;