import React from 'react';
import {Button, Stack} from "@mui/material";

const AppBarItems = ({menuItems, location}) => {

    return (
        <Stack
            direction='row'
            gap={4}
            sx={{display: { xs: 'none', lg: 'flex' }, justifyContent:'center'}}
        >
            {
                menuItems.map(item => {
                    return(
                        <Button color={"primary"} startIcon={item.icon ? item.icon : null} onClick={item.handler}>{item.label}</Button>
                    );
                })
            }
        </Stack>
    );
};

export default AppBarItems;