import React from 'react';
import {Chip} from "@mui/material";

const BondStatusChip = ({status, maturity, chipSize='medium'}) => {
    const isInstrumentActive = (maturity > (new Date())) && status
    return (
        <Chip
            sx={{borderRadius:1}}
            size={chipSize}
            label={isInstrumentActive ? 'Active' : !status ? 'Inactive' : 'Reached maturity'}
            color={isInstrumentActive ? 'success' : !status ? 'error' : 'warning'}
        />
    );
};

export default BondStatusChip;