import {dateFormatter} from "./formatterUtil";

const historicIndicatorChartDataFormatter = (label, points) => {
    console.log(points)
    return({
        labels: points.map((point) => dateFormatter(point.date, 'short')),
        datasets: [
            {label, data: points.map((point) => point.value)}
        ]
    })
}

const historicPriceChartDataFormatter = (points) => {
    return({
        labels: points.map((point) => dateFormatter(point.sessionDate, 'short')),
        datasets: [
            {label: 'Bond price', data: points.map((point) => point.price)}
        ]
    })
}

const historicYieldChartDataFormatter = (points) => {
    return({
        labels: points.map((point) => dateFormatter(point.sessionDate, 'short')),
        datasets: [
            {label: 'Bond yield', data: points.map((point) => point.yield)}
        ]
    })
}

const getFormattedPlottingData = (type, points) => {
    switch(type){
        case ("price"):
            return historicPriceChartDataFormatter(points)
        case ("yield"):
            return historicYieldChartDataFormatter(points)
        default:
            return historicPriceChartDataFormatter(points)
    }
}

const getDateCorrespondingToRange = (lastSessionDate, firstSessionDate, range) => {
    switch(range){
        case ('5D'):
                return (new Date(lastSessionDate.setDate(lastSessionDate.getDate() - 5))).toDateString()
        case ('1M'):
            return (new Date(lastSessionDate.setMonth(lastSessionDate.getMonth() - 1))).toDateString()
        case ('6M'):
            return (new Date(lastSessionDate.setMonth(lastSessionDate.getMonth() - 6))).toDateString()
        case ('YTD'):
            return (new Date(lastSessionDate.getFullYear(), 0, 1)).toDateString()
        case ('1Y'):
            return (new Date(lastSessionDate.setFullYear(lastSessionDate.getFullYear() - 1))).toDateString()
        case ('5Y'):
            return (new Date(lastSessionDate.setFullYear(lastSessionDate.getFullYear() - 5))).toDateString()
        case ('MAX'):
            return firstSessionDate.toDateString()
        default:
            return (new Date(lastSessionDate.setDate(lastSessionDate.getDate() - 5))).toDateString()
    }
}


export{
    getFormattedPlottingData,
    getDateCorrespondingToRange,
    historicIndicatorChartDataFormatter,
}