import React from 'react';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {Chip} from "@mui/material";
import {capitalizeFirstLetterFormatter} from "../../../../util/formatterUtil";

const OrderFilterChip = ({label, value, currentFilter, isDescSort, toggleHandler}) => {
    return (
        <Chip
            label={capitalizeFirstLetterFormatter((label))}
            color="primary"
            icon={currentFilter === value ? isDescSort ? <KeyboardArrowDownIcon/> : <KeyboardArrowUpIcon/> : null}
            variant={currentFilter === value ? 'contained' : 'outlined'}
            onClick={() => toggleHandler(value)}/>
    );
};

export default OrderFilterChip;