/**
 * Utilitarian function that formats a date as ISO string. Used to API request.
 * @param date {Date} Target date to cast into ISO string to request API.
 * @returns {string}
 */
const getAPIDateString = (date) => {
    return date.toISOString().slice(0, 10)
}

export {
    getAPIDateString,
}