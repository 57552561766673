import React from 'react';
import {Paper, Stack, Typography} from "@mui/material";
import CustomLineChart from "../charts/CustomLineChart";
import DataPointDelta from "../DataPointDelta";

const IndicatorCardChart = ({indicator, currentIndicator, history, flex}) => {
    return (
        <Stack sx={{flex:flex, p:2}} component={Paper} gap={2}>
            <Typography variant="subtitle1" fontWeight="bold">{history.datasets[0].label}</Typography>
            <DataPointDelta
                label="Value"
                currentValue={indicator.target.value}
                referenceValue={indicator.offset.value}
                referenceTimeRange="1M"
                unit={currentIndicator === "averageExchangeRate" ? "₡" : "%"}
            />
            <CustomLineChart chartData={history}/>
        </Stack>
    );
};

export default IndicatorCardChart;