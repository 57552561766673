import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import {Chip} from "@mui/material";

const ToggleFilterChip = ({label, isSelected, toggleHandler}) => {
    return (
        <Chip
            label={label}
            icon={isSelected ? <CheckIcon/> : null}
            variant={isSelected ? 'contained' : 'outlined'}
            onClick={toggleHandler}/>
    );
};

export default ToggleFilterChip;