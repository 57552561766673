import React from 'react';
import {Typography, Stack, ListItemButton} from "@mui/material";
import {bondNameFormatter, dateFormatter} from "../../util/formatterUtil"
import {useNavigate} from "react-router-dom";

const SearchSuggestion = ({props, suggestion}) => {

    const navigate = useNavigate()

    return (
        <ListItemButton {...props} onClick={() => navigate('/bond/' + suggestion.isin)} sx={{display:'flex',justifyContent:'space-between'}}>
            <Stack>
                <Typography color="primary.main">{bondNameFormatter(suggestion)}</Typography>
                <Typography color="text.secondary">{suggestion.isin}</Typography>
            </Stack>
        </ListItemButton>
    );
};

export default SearchSuggestion;