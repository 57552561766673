import React, {useContext, useEffect, useState} from 'react';
import {Paper, useMediaQuery, Autocomplete} from "@mui/material";
import {instrumentSearchSuggestions} from "../../api/app/instrumentRequests";
import Context from "../../context/Context";
import SearchSuggestion from "./SearchSuggestion";
import SearchInput from "./SearchInput";

const SearchBar = ({searchBarValue, searchBarChangeHandler, performSearch}) => {

    const {showSnackbar} = useContext(Context);

    const [searchSuggestions, setSearchSuggestions] = useState([])
    const [highlightedResult, setHighlightedResult] = useState({})

    const upLGScreenSize = useMediaQuery((theme) => theme.breakpoints.up('lg'));

    const onSubmitHandler = (e) => {
        if(e){ e.preventDefault() }
        performSearch()
        setSearchSuggestions([])
    }

    useEffect(() => {
        if(searchBarValue.length){
            instrumentSearchSuggestions(searchBarValue).then((suggestion) => {setSearchSuggestions(suggestion)}).catch(e => {
                showSnackbar(e.message, 'warning');
            })
        }else{
            setSearchSuggestions([])
        }
    }, [searchBarValue])

    return (
        <Paper
            component="form"
            onSubmit={onSubmitHandler}
            sx={{flex:1}}
        >
            <Autocomplete
                freeSolo
                filterOptions={(x) => x}
                disableClearable
                options={(upLGScreenSize && searchSuggestions) || [] }
                value={searchBarValue}
                onChange={(event, newValue) => { setHighlightedResult(newValue) }}
                onHighlightChange={(event, newValue) => { setHighlightedResult(newValue) }}
                onInputChange={(e) => { e && searchBarChangeHandler(e.target.value) }}
                renderOption={(props, option) => { return( <SearchSuggestion props={props} suggestion={option}/>) }}
                renderInput={(params) => (<SearchInput params={params} highlightedResult={highlightedResult} onSubmitHandler={onSubmitHandler}/> )}
            />
        </Paper>
    );
};

export default SearchBar;