import React from 'react';
import {Box, Typography} from "@mui/material";
import {grey} from "@mui/material/colors";

const TendencyBadge = ({tendency, variation}) => {

    const tendencyColor = tendency === "up" ? "success.main" : tendency === "down" ? "error.main" : grey[600]

    return (
        <Box sx={{backgroundColor:tendencyColor,  px:0.75, borderRadius:1}}>
            <Typography color={"white"}>{variation > 0 && "+"}{variation.toFixed(2)}%</Typography>
        </Box>
    );
};

export default TendencyBadge;