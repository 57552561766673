import React from 'react';
import {AppBar, Box, Button, IconButton, Toolbar, useMediaQuery} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import {Link, useLocation, useNavigate} from "react-router-dom";
import AppBarItems from "./AppBarItems";
import logo from "../../assets/main_logo.png";
import white_logo from "../../assets/white_logo.png";
import AppBarDrawer from "./AppBarDrawer";
import SearchIcon from "@mui/icons-material/Search";

const MainAppBar = (props) => {

    const { window } = props;

    const navigate = useNavigate()

    const location = useLocation()

    const [drawerToggle, setDrawerToggle] = React.useState(false);

    const upMDScreenSize = useMediaQuery((theme) => theme.breakpoints.up('md'));

    const drawerToggleHandler = () => { setDrawerToggle((prevState) => !prevState);};

    const controlledNavigationHandler = (path) => {
        location.pathname !== path && navigate(path)
    }

    const menuItems = [
        {
            icon: <SearchIcon/>,
            label:'Explore Bonds',
            handler: () => controlledNavigationHandler('/bonds')
        },
        // {
        //     label:'Indicators & Indexes',
        //     handler: () => controlledNavigationHandler('/indicators-indexes')
        // },
        // {
        //     label:'Porftolio calculator',
        //     handler: () => controlledNavigationHandler('/portfolio-calculator')
        // },
    ]

    return(
        <Box sx={{display: 'flex', mb: 10, ml:-2, mr:-3}}>
            <AppBar component="nav" position="sticky" sx={{backgroundColor:'transparent', pt:2, borderWidth:0}}>
                <Toolbar sx={{justifyContent:'space-between'}}>
                    <Link to='/'>
                        <img style={{width: 125}} src={(upMDScreenSize || location.pathname !== "/") ? logo : white_logo} alt='Inverso logo'/>
                    </Link>
                    <IconButton
                        aria-label="open drawer"
                        edge="start"
                        color={upMDScreenSize || location.pathname !== "/" ? "primary" : "secondary"}
                        onClick={drawerToggleHandler}
                        sx={{mr: 2, display: {lg: 'none'}}}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <AppBarItems menuItems={menuItems} location={location}/>
                    <Button
                        href="https://github.com/andresmg07"
                        target="_blank"
                        rel="noopener noreferrer"
                        variant="contained"
                        color="primary"
                        sx={{display: { xs: 'none', lg: 'block' }}}
                    >Who developed this platform?</Button>
                </Toolbar>
            </AppBar>
            <nav>
                <AppBarDrawer drawerToggle={drawerToggle} drawerToggleHandler={drawerToggleHandler} items={menuItems} navigate={navigate} window={window}/>
            </nav>
        </Box>
    );
};

export default MainAppBar;

