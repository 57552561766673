import React from 'react';
import {Stack, Grid, Typography} from "@mui/material";
import FeaturedBondCard from "./bondCard/FeaturedBondCard";
import LoadingWrapper from "./LoadingWrapper";
import SimpleBondCard from "./bondCard/SimpleBondCard";

const FeaturedInstruments = ({title, bonds}) => {
    return (
        <Stack>
            <Typography variant='subtitle1' sx={{ fontWeight: 'bold', mb:2 }}>{title}</Typography>
            <LoadingWrapper>
                    <Grid container spacing={2} >
                        {
                            bonds.map((bond) => {
                                return(
                                    <Grid item xs={12} lg={4} >
                                        <SimpleBondCard bond={bond}/>
                                    </Grid>
                                );
                            })
                        }
                    </Grid>

            </LoadingWrapper>
        </Stack>
    );
};

export default FeaturedInstruments;