import React from 'react';
import {Typography, useMediaQuery} from "@mui/material";

const CompoundHeader = ({countryName, countryFlag}) => {

    const upLGScreenSize = useMediaQuery((theme) => theme.breakpoints.up('lg'));

    return (
        <Typography variant={upLGScreenSize ? 'h3' : 'h4'} sx={{fontWeight: 'bold'}}>{countryFlag} {countryName}</Typography>
    );
};

export default CompoundHeader;