import React, {useContext, useEffect, useState} from 'react';
import {getFeaturedInstruments} from "../../api/app/instrumentRequests";
import Context from "../../context/Context";
import {Box, Stack, Typography, useMediaQuery} from "@mui/material";
import SearchBar from "../../components/searchBar/SearchBar";
import {useNavigate} from "react-router-dom";
import ResponsiveLayout from "../../components/layout/ResponsiveLayout";
import {
    getCurrentDollarColonAverageExchangeRateWithReference,
    getCurrentTBPWithReference,
    getCurrentTEDWithReference,
    getCurrentTPMWithReference,
    getHistoricDollarColonAverageExchangeRate,
    getHistoricMONEXExchangeRateAverage,
    getHistoricTBP,
    getHistoricTED,
    getHistoricTPM
} from "../../api/proxy/indicatorRequests";
import IndicatorCard from "../../components/indicatorCard/IndicatorCard";
import {historicIndicatorChartDataFormatter} from "../../util/chartUtil";
import BannerLayout from "../../components/layout/BannerLayout";
import FeaturedBondTable from "../../components/bondCard/FeaturedBondTable";
import ExchangeRateCard from "../../components/indicatorCard/ExchangeRateCard";
import IndicatorCardChart from "../../components/indicatorCard/IndicatorCardChart";
import LoadingWrapper from "../../components/LoadingWrapper";

const Dashboard = () => {

    const navigate = useNavigate()

    const downMDScreenSize = useMediaQuery((theme) => theme.breakpoints.down('md'));

    const [isFeaturedLoading, setIsFeaturedLoading] = useState(true)
    const [isIndicatorsLoading, setIsIndicatorsLoading] = useState(true)
    const [isHistoricIndicatorsLoading, setIsHistoricIndicatorsLoading] = useState(true)

    const {showSnackbar} = useContext(Context)

    const [firstSessionFeaturedBonds, setFirstSessionFeaturedBonds] = useState([])
    const [rateFeaturedBonds, setRateFeaturedBonds] = useState([])
    const [maturityFeaturedBonds, setMaturityFeaturedBonds] = useState([])
    const [priceFeaturedBonds, setPriceFeaturedBonds] = useState([])

    const [indicators, setIndicators] = useState({})
    const [historicIndicators, setHistoricIndicators] = useState({})
    const [currentIndicator, setCurrentIndicator] = useState("averageExchangeRate")

    const [searchBarValue, setSearchBarValue] = useState("")


    const onSubmitSearchBarHandler = () => {
        navigate(`/bonds?searchTerm=${encodeURIComponent(searchBarValue)}`);
    }

    useEffect(() => {
        getFeaturedInstruments('release', 'upper').then( (releaseFeaturedBonds) => {
            setFirstSessionFeaturedBonds(releaseFeaturedBonds)
        }).catch(e => {
            showSnackbar(e.message, 'warning');
        })

        getFeaturedInstruments('maturity', 'upper').then( (maturityFeaturedBonds) => {
            setMaturityFeaturedBonds(maturityFeaturedBonds)
        }).catch(e => {
            showSnackbar(e.message, 'warning');
        })

        getFeaturedInstruments('rate', 'upper').then( (rateFeaturedBonds) => {
            setRateFeaturedBonds(rateFeaturedBonds)
        }).catch(e => {
            showSnackbar(e.message, 'warning');
        })

        getFeaturedInstruments('price', 'upper').then( (priceFeaturedBonds) => {
            setPriceFeaturedBonds(priceFeaturedBonds)
        }).catch(e => {
            showSnackbar(e.message, 'warning');
        })


        getCurrentTBPWithReference(30).then((tbp) => {
            getCurrentTPMWithReference(30).then((tpm) => {
                getCurrentTEDWithReference(30).then((ted) => {
                    getCurrentDollarColonAverageExchangeRateWithReference(30).then(averageExchangeRate => {
                        console.log(averageExchangeRate)
                        setIndicators({
                            averageExchangeRate,
                            ted,
                            tpm,
                            tbp
                        })
                    }).catch(e => {
                        showSnackbar(e.message, 'warning');
                    })
                }).catch(e => {
                    showSnackbar(e.message, 'warning');
                })
            }).catch(e => {
                showSnackbar(e.message, 'warning');
            })
        }).catch(e => {
            showSnackbar(e.message, 'warning');
        })

        const historicEnd = new Date();
        const historicStart = new Date();
        historicStart.setMonth(historicStart.getMonth() - 1);

        getHistoricTBP(historicStart, historicEnd).then((tbp) => {
            getHistoricTED(historicStart, historicEnd).then((ted) => {
                getHistoricTPM(historicStart, historicEnd).then((tpm) => {
                    getHistoricDollarColonAverageExchangeRate(historicStart, historicEnd).then((averageExchangeRate) => {
                        setHistoricIndicators({
                            tpm: historicIndicatorChartDataFormatter("TPM", tpm),
                            tbp: historicIndicatorChartDataFormatter("TBP", tbp),
                            ted: historicIndicatorChartDataFormatter("TED", ted),
                            averageExchangeRate: historicIndicatorChartDataFormatter("Average colon/dollar exchange rate",averageExchangeRate)
                        })
                    }).catch(e => {
                        showSnackbar(e.message, 'warning');
                    })
                }).catch(e => {
                    showSnackbar(e.message, 'warning');
                })
            }).catch(e => {
                showSnackbar(e.message, 'warning');
            })
        }).catch(e => {
            showSnackbar(e.message, 'warning');
        })
    }, [])

    useEffect(() => {
        if(indicators["averageExchangeRate"] !== undefined){
            setIsIndicatorsLoading(false)
        }
        if(historicIndicators["averageExchangeRate"] !== undefined){
            setIsHistoricIndicatorsLoading(false)
        }
    },[historicIndicators, indicators])

    useEffect(() => {
        if(firstSessionFeaturedBonds.length > 0 && priceFeaturedBonds.length > 0 && rateFeaturedBonds.length > 0 && maturityFeaturedBonds.length > 0){
            setIsFeaturedLoading(false)
        }
    }, [firstSessionFeaturedBonds, priceFeaturedBonds, rateFeaturedBonds, maturityFeaturedBonds]);

    return (
            <Stack gap={6} sx={{mt: { xs:-20, md: - 5}}}>
                <BannerLayout>
                    <Stack gap={10} sx={{py:15}}>
                        <Stack gap={{xs:4, md:2}}>
                            <Typography color="white" variant={downMDScreenSize ? 'h3' : 'h2'} fontWeight='bold' sx={{textAlign: "left"}}>Explore over 5 thousand bonds</Typography>
                            <Typography color="white" variant={downMDScreenSize ? 'h5' : 'h4'} sx={{textAlign: "left"}}>Listed at Costa Rica Stock Market</Typography>
                        </Stack>
                        <Box sx={{display:"flex", width: {xs: '100%', md:'50%'} }}>
                            <SearchBar
                                performSearch={onSubmitSearchBarHandler}
                                searchBarValue={searchBarValue}
                                searchBarChangeHandler={setSearchBarValue}
                            />
                        </Box>
                    </Stack>
                </BannerLayout>
                <Stack gap={8}>
                    <Stack gap={4}>
                        <Typography variant={downMDScreenSize ? 'h4' : 'h5'} fontWeight="bold">Featured Bonds</Typography>
                        <LoadingWrapper isLoading={isFeaturedLoading}>
                            <Stack gap={2}>
                                <ResponsiveLayout>
                                    <FeaturedBondTable bonds={rateFeaturedBonds} featureSubheader="The best value for your money" featureCaption="Higher coupon bonds" flex={0.5}/>
                                    <FeaturedBondTable bonds={priceFeaturedBonds} featureSubheader="Most wanted" featureCaption="Higher price bonds" flex={0.5}/>
                                </ResponsiveLayout>
                                <ResponsiveLayout>
                                    <FeaturedBondTable bonds={firstSessionFeaturedBonds} featureSubheader="Freshly listed" featureCaption="Recently added bonds" flex={0.5}/>
                                    <FeaturedBondTable bonds={maturityFeaturedBonds} featureSubheader="Long run options" featureCaption="Farther from maturity bonds" flex={0.5}/>
                                </ResponsiveLayout>
                            </Stack>
                        </LoadingWrapper>
                    </Stack>
                    <Stack gap={4}>
                        <Typography variant={downMDScreenSize ? 'h4' : 'h5'} fontWeight="bold">Costa Rica key indicators</Typography>
                        <LoadingWrapper isLoading={isIndicatorsLoading && isHistoricIndicatorsLoading}>
                            <ResponsiveLayout>
                                    <Stack gap={2} direction={{xs:"column", md:"row", lg:"column"}} sx={{flex:{xs:1, lg: 0.35}}}>
                                        {indicators["averageExchangeRate"] !== undefined && <ExchangeRateCard indicator="averageExchangeRate" onClickHandler={setCurrentIndicator} flex={{xs:1, lg:0.5}} title="Average colon/dollar exchange rate" data={indicators["averageExchangeRate"]}/>}
                                        {indicators["tpm"] !== undefined && <IndicatorCard indicator="tpm" onClickHandler={setCurrentIndicator} flex={{xs:1, lg:0.5}} title="TPM" data={indicators["tpm"]}/>}
                                        {indicators["ted"] !== undefined && <IndicatorCard indicator="ted" onClickHandler={setCurrentIndicator} flex={{xs:1, lg:0.5}} title="TED" data={indicators["ted"]}/>}
                                        {indicators["tbp"] !== undefined && <IndicatorCard indicator="tbp" onClickHandler={setCurrentIndicator} flex={{xs:1, lg:0.5}} title="TBP" data={indicators["tbp"]}/>}
                                    </Stack>
                                    { !downMDScreenSize && indicators[currentIndicator] && historicIndicators[currentIndicator] &&
                                        <IndicatorCardChart
                                            indicator={indicators[currentIndicator]}
                                            currentIndicator={currentIndicator}
                                            history={historicIndicators[currentIndicator]}
                                            flex={0.65}
                                        />
                                    }
                            </ResponsiveLayout>
                        </LoadingWrapper>
                    </Stack>
                </Stack>
                {/*<HowWorksBanner/>*/}
            </Stack>
    );
};

export default Dashboard;