import React from 'react';
import {Grid} from "@mui/material";
import DetailedBondCard from "../../../components/bondCard/DetailedBondCard"

const BondGrid = ({bonds}) => {

    return (
        <Grid container spacing={4}>
            {
                bonds.map((bond) => {
                    return(
                        <Grid item xs={12} md={6}>
                            <DetailedBondCard bond={bond}/>
                        </Grid>
                    );
                })
            }
        </Grid>
    );
};

export default BondGrid;