import React from 'react';
import {Box, Typography} from "@mui/material";
import {currencySymbolFormatter} from "../util/formatterUtil";

const CurrencyIndicator = ({currency}) => {
    return (
        <Box
            sx={{
                backgroundColor:'primary.main',
                borderRadius:50,
                width:25,
                height:25,
                textAlign:'center'
            }}
        >
            <Typography
                sx={{
                    fontWeight: 'bold',
                    color: 'white',
                }}
            >{currencySymbolFormatter(currency)}</Typography>
        </Box>
    );
};

export default CurrencyIndicator;