import React, {useContext, useEffect, useState} from 'react';
import {Stack, Paper, Typography, Box} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import Context from "../../context/Context";
import {getAllInstruments, getInstrumentDetail, instrumentSearchSuggestions} from "../../api/app/instrumentRequests";
import {
    getComplementaryHistory,
    getInitialHistoricPricePoints,
    getRangedHistoricPricePoints
} from "../../api/app/historicRequests";
import {
    amountFormatter,
    booleanDataPointFormatter, capitalizeFirstLetterFormatter,
    currencyAmountFormatter,
    dateFormatter, listFormatter,
} from "../../util/formatterUtil";
import {getDateCorrespondingToRange} from "../../util/chartUtil";
import {CompoundHeader, HistoricChart, HighlightsCard, DataCard} from "./components"
import LoadingWrapper from "../../components/LoadingWrapper";
import {
    getBasicCountryDataRequest, getBasicEconomicAndGrowthIndicatorsRequest
} from "../../api/country/issuerCountryRequests";
import SimpleInstrumentGroup from "../../components/SimpleInstrumentGroup";
import ResponsiveLayout from "../../components/layout/ResponsiveLayout";

const Bond = () => {

    const {id} = useParams()

    const {showSnackbar} = useContext(Context)

    const navigate = useNavigate()

    const historicPointsPlottingRanges = ["5D","1M","6M","YTD","1Y","5Y","MAX"]

    const [isBondDataLoading, setIsBondDataLoading] = useState(true)

    const [bondDetail, setBondDetail] = useState(null)
    const [historicPoints, setHistoricPoints] = useState([])
    const [plottingHistoricPoints, setPlottingHistoricPoints] = useState([])
    const [plottingRange, setPlottingRange] = useState("")
    const [plottingType, setPlottingType] = useState("")
    const [complementaryHistory, setComplementaryHistory] = useState(null)
    const [complementaryHistoryDisplay, setComplementaryHistoryDisplay] = useState(null)
    const [bondHighlights, setBondHighlights] = useState([])
    const [generalInformation, setGeneralInformation] = useState([])
    const [masterInformation, setMasterInformation] = useState([])
    const [countryBasicData, setCountryBasicData] = useState(null)
    const [countryEconomicAndGrowthData, setCountryEconomicAndGrowthData] = useState(null)
    const [countryDataDisplay, setCountryDataDisplay] = useState([])
    const [chartRangeToggleOptions, setChartRangeToggleOptions] = useState([])
    const [chartTypeToggleOptions, setChartTypeToggleOptions] = useState([
        {label: "Price", value:"price"},
        {label: "Yield", value:"yield"},
    ])
    const [relatedBonds, setRelatedBonds] = useState([])


    const clearState = () => {
        setBondDetail(null)
        setComplementaryHistory(null)
        setPlottingHistoricPoints([])
    }

    const handlePlottingRangeChangeHandler = (event, range) => {
        if (range !== null) {
            setPlottingRange(range);
        }
    }

    const handlePlottingTypeChangeHandler = (event, type) => {
        if (type !== null) {
            setPlottingType(type);
        }
    }

    const plotHistoricPoint = (targetLimitDate) => {
        const rangedPlottingPoints = historicPoints.filter(point => point.sessionDate > targetLimitDate)
        setPlottingHistoricPoints(rangedPlottingPoints)
    }

    useEffect(() => {
        if (bondDetail){

            getInitialHistoricPricePoints(id, new Date(bondDetail.lastSessionDate), 5).then((points) => {setHistoricPoints(points)}).catch(e => {
                showSnackbar(e.message, 'warning');
            })

            getBasicCountryDataRequest(bondDetail.countryCode).then(basicData => setCountryBasicData(basicData)).catch(e => {
                showSnackbar(e.message, 'warning');
            })

            getBasicEconomicAndGrowthIndicatorsRequest(bondDetail.countryCode).then(data => setCountryEconomicAndGrowthData(data)).catch(e => {
                showSnackbar(e.message, 'warning');
            })

            getAllInstruments(0, 3, false, bondDetail.issuer, "rate", true).then(instruments => { setRelatedBonds(instruments) }).catch(e => {
                showSnackbar(e.message, 'warning');
            })

            setBondHighlights([
                {label: 'Price', value: bondDetail.price.toFixed(2) + '%'},
                {label: 'YTM', value: bondDetail.yield.toFixed(2) + '%'},
                {label: 'Coupon', value: bondDetail.rate.toFixed(2) + '%'},
                {label: 'Maturity', value: dateFormatter(bondDetail.maturity)},
            ])

            setGeneralInformation([
                {label: 'Series', value: bondDetail.series},
                {label: 'Issuance type', value: bondDetail.issuanceType},
                {label: 'Instrument type', value: bondDetail.instrumentType},
                {label: 'Interest payment count (per year)', value: bondDetail.peridiocity},
                {label: 'Official', value: booleanDataPointFormatter(bondDetail.official)},
                {label: 'Buy back', value: booleanDataPointFormatter(bondDetail.buyBack)},
            ])

            setMasterInformation([
                {label: 'Country', value: bondDetail.country},
                {label: 'Issuer', value: bondDetail.issuer},
                {label: 'Currency', value: bondDetail.currency},
                {label: 'Approved amount', value: currencyAmountFormatter(bondDetail.currency, bondDetail.approvedAmount)},
                {label: 'Placed amount', value: currencyAmountFormatter(bondDetail.currency, bondDetail.placedAmount)},
                {label: 'First session recorded', value: dateFormatter(bondDetail.firstSessionDate, 'short')},
                {label: 'Last session recorded', value: dateFormatter(bondDetail.lastSessionDate, 'short')},
            ])

            setPlottingRange(getDateCorrespondingToRange(new Date(bondDetail.lastSessionDate), new Date(bondDetail.firstSessionDate), historicPointsPlottingRanges[0]))
            setPlottingType(chartTypeToggleOptions[0].value)
            setChartRangeToggleOptions(historicPointsPlottingRanges.map((range) =>{
                return({
                    label: range,
                    value: getDateCorrespondingToRange(new Date(bondDetail.lastSessionDate), new Date(bondDetail.firstSessionDate), range)
                });
            }))
        }
    }, [bondDetail])

    useEffect(() => {
        if(countryBasicData && countryEconomicAndGrowthData){
            setCountryDataDisplay([
                {label: 'Official name', value: countryBasicData.officialName},
                {label: 'Capital', value: countryBasicData.capital},
                {label: 'Region', value: countryBasicData.region},
                {label: 'Currencies', value: listFormatter(countryBasicData.currencies)},
                {label: countryEconomicAndGrowthData.gdp.label, value: currencyAmountFormatter("Dollars", countryEconomicAndGrowthData.gdp.value)},
                {label: countryEconomicAndGrowthData.gdpGrowth.label, value: amountFormatter(countryEconomicAndGrowthData.gdpGrowth.value/100, "percent")},
            ])
        }
    }, [countryEconomicAndGrowthData]);

    useEffect(() => {
        if (historicPoints.length) {
            const currentLimitDate = new Date(historicPoints[0].sessionDate)
            const targetLimitDate = new Date(plottingRange)
            if (targetLimitDate < currentLimitDate) {
                getRangedHistoricPricePoints(id, targetLimitDate, currentLimitDate).then((rangedPoints) => {
                    setHistoricPoints(rangedPoints.concat(historicPoints))
                }).catch(e => {
                    showSnackbar(e.message, 'warning');
                })
            } else {
                plotHistoricPoint(new Date(plottingRange))
            }
        }
    }, [plottingRange])

    useEffect(() => {
        plotHistoricPoint(new Date(plottingRange))
    }, [historicPoints])

    useEffect(() => {
        setIsBondDataLoading(true)
        clearState()
        getInstrumentDetail(id).then((detail) => {setBondDetail(detail)}).catch(e => {
            showSnackbar(e.message, 'warning');
        })
        getComplementaryHistory(id).then((history) => {setComplementaryHistory(history)}).catch(e => {
            showSnackbar(e.message, 'warning');
        })
    }, [id])

    useEffect(() => {
        if(complementaryHistory){
            if(complementaryHistory.lastYearsPriceYieldLimits){
                setComplementaryHistoryDisplay([
                    {label: "Day trading volume level", value: capitalizeFirstLetterFormatter(complementaryHistory.current_activity_level)},
                    {label: "Current trading volume level", value: capitalizeFirstLetterFormatter(complementaryHistory.current_activity_level)},
                    {label:"Last 52 week price range" , value: `${currencyAmountFormatter("Dollars", complementaryHistory.lastYearsPriceYieldLimits.lower.price)} - ${currencyAmountFormatter("Dollars", complementaryHistory.lastYearsPriceYieldLimits.higher.price)}`},
                    {label:"Absolute price range" , value: `${currencyAmountFormatter("Dollars", complementaryHistory.absolutePriceYieldLimits.lower.price)} - ${currencyAmountFormatter("Dollars", complementaryHistory.absolutePriceYieldLimits.higher.price)}`},
                    {label:"Last 52 week yield range" , value: `${amountFormatter(complementaryHistory.lastYearsPriceYieldLimits.higher.yield/100, "percent")} - ${amountFormatter(complementaryHistory.lastYearsPriceYieldLimits.lower.yield/100, "percent")}`},
                    {label:"Absolute yield range" , value: `${amountFormatter(complementaryHistory.absolutePriceYieldLimits.higher.yield/100, "percent")} - ${amountFormatter(complementaryHistory.absolutePriceYieldLimits.lower.yield/100, "percent")}`},
                ])
            }else{
                setComplementaryHistoryDisplay([
                    {label: "Day trading volume level", value: capitalizeFirstLetterFormatter(complementaryHistory.current_activity_level)},
                    {label: "Current trading volume level", value: capitalizeFirstLetterFormatter(complementaryHistory.current_activity_level)},
                    {label:"Last 52 week price range" , value: "No available data for this time range."},
                    {label:"Absolute price range" , value: `${currencyAmountFormatter("Dollars", complementaryHistory.absolutePriceYieldLimits.lower.price)} - ${currencyAmountFormatter("Dollars", complementaryHistory.absolutePriceYieldLimits.higher.price)}`},
                    {label:"Last 52 week yield range" , value: "No available data for this time range."},
                    {label:"Absolute yield range" , value: `${amountFormatter(complementaryHistory.absolutePriceYieldLimits.higher.yield/100, "percent")} - ${amountFormatter(complementaryHistory.absolutePriceYieldLimits.lower.yield/100, "percent")}`},
                ])
            }
        }

        if(bondDetail && complementaryHistory && plottingHistoricPoints.length > 0){
            setIsBondDataLoading(false)
        }
    }, [bondDetail, complementaryHistory, plottingHistoricPoints])

    return (
        <LoadingWrapper isLoading={isBondDataLoading}>
            <Stack>
                <CompoundHeader bondDetail={bondDetail}/>
                <Stack gap={2} sx={{mt:8}}>
                    <HighlightsCard highlights={bondHighlights}/>
                    <ResponsiveLayout>
                        <Stack component={Paper} sx={{p:2, flex:{xs:1, lg:0.75}}}>
                            <Typography variant="subtitle1" fontWeight="bold">Trading chart</Typography>
                            <HistoricChart
                                plottingHistoricPoints={plottingHistoricPoints}
                                plottingRange={plottingRange}
                                handlePlottingRangeChangeHandler={handlePlottingRangeChangeHandler}
                                chartRangeToggleOptions={chartRangeToggleOptions}
                                plottingType={plottingType}
                                handlePlottingTypeChangeHandler={handlePlottingTypeChangeHandler}
                                chartTypeToggleOptions={chartTypeToggleOptions}
                            />
                        </Stack>
                        <DataCard flex={{xs:1, lg:0.25}} title="Master information" data={masterInformation}/>
                    </ResponsiveLayout>
                    <ResponsiveLayout>
                        <DataCard flex={{xs:1, lg:0.5}} title="General information" data={generalInformation}/>
                        <DataCard flex={{xs:1, lg:0.5}} title="Performance information" data={complementaryHistoryDisplay}/>
                    </ResponsiveLayout>
                    <DataCard flex={1} title="Country information" data={countryDataDisplay} seeMoreHandler={() => navigate(`/country/${bondDetail.countryCode}`)}/>
                    <SimpleInstrumentGroup title="Related bonds" bonds={relatedBonds}/>
                </Stack>
            </Stack>
        </LoadingWrapper>
    );
};

export default Bond;