const fetchAppAPI = async (params, query='') => {
    const res = await fetch(process.env.REACT_APP_APP_API_URL + params + '?' + query)
    if(!res.ok) throw new Error('Internal server error, please try again later.');
    return await res.json()
}

const fetchProxyAPI = async (params, query='') => {
    const res = await fetch(process.env.REACT_APP_PROXY_API_URL + params + '?' + query)
    if(!res.ok) throw new Error('Internal server error, please try again later.');
    return await res.json()
}

const fetchCountryAPI = async (params, query='') => {
    const res = await fetch(process.env.REACT_APP_COUNTRY_API_URL + params + '?' + query)
    if(!res.ok) throw new Error('Internal server error, please try again later.');
    return await res.json()
}

const fetchWorldBankAPI = async (params, query='') => {
    const res = await fetch(process.env.REACT_APP_WB_API_URL + params + '?' + query)
    if(!res.ok) throw new Error('Internal server error, please try again later.');
    return await res.json()
}




export {
    fetchAppAPI,
    fetchProxyAPI,
    fetchCountryAPI,
    fetchWorldBankAPI,
}