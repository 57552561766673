import React from 'react';
import {Stack, Typography} from "@mui/material/";
import { grey } from '@mui/material/colors';
import {tendencyFormatter, timeRangeFormatter} from "../util/formatterUtil";
import TendencyIcon from "./TendencyIcon";


const DataPointDelta = ({label, currentValue, referenceValue, referenceTimeRange=null, unit="%"}) => {

    const variation = currentValue !== 0 ? ((currentValue - referenceValue) / currentValue) * 100 : 0
    const tendency = tendencyFormatter(variation)
    const tendencyColor = tendency === "up" ? "success.main" : tendency === "down" ? "error.main" : grey[600]

    return (
        <Stack>
            <Typography color="text.secondary">{label}</Typography>
            <Typography variant="h4" fontWeight="bold">{unit === "₡" && unit}{currentValue.toFixed(2)}{unit === "%" && unit}</Typography>
            <Stack direction="row" alignItems="center">
                <Typography color={tendencyColor}>{variation > 0 && "+"}{variation.toFixed(2)}%</Typography>
                <TendencyIcon tendency={tendency} />
                <Typography color={tendencyColor}>{referenceTimeRange && timeRangeFormatter(referenceTimeRange)}.</Typography>
            </Stack>
        </Stack>
    );
};

export default DataPointDelta;