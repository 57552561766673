import React from 'react';
import {Stack} from "@mui/material";

const BannerLayout = ({children}) => {
    return (
        <Stack
            sx={{
                mx: {xs: -5, md:0},
                px: {lg:20, md:10, xs:5},
                borderRadius: {xs:"none", md: 8},
                background:'radial-gradient(ellipse at left bottom, #001d3d, 45%, #0046e1 )',
            }}
        >
            {children}
        </Stack>
    );
};

export default BannerLayout;