import {fetchAppAPI} from "../../util/APIUtil";
import {normalizeDataPointValueUtil} from "../../util/normalizationUtil";

const getFeaturedInstruments =  (feature, boundary) => {
    return new Promise(async (resolve, reject) => {
        try{
            const params = "/instruments/featured/" + feature
            const query = `boundary=${boundary}`
            const data = await fetchAppAPI(params, query)
            resolve(data.map((instrument) => {
                return({
                    ...instrument,
                    maturity: new Date(instrument.maturity),
                });
            }))
        }catch (e){
            reject(e)
        }
    })
}

const getInstrumentDetail = async (searchTerm) => {
    return new Promise(async (resolve, reject) => {
        try{
            const params = "/instruments/detail/" + searchTerm
            const data = await fetchAppAPI(params)
            resolve({
                ...data,
                firstSessionDate: new Date(data.firstSessionDate),
                lastSessionDate: new Date(data.lastSessionDate),
                maturity: new Date(data.maturity),
                price: normalizeDataPointValueUtil(data.price),
                yield: normalizeDataPointValueUtil(data.yield),
                rate: normalizeDataPointValueUtil(data.rate),
            })
        }catch (e){
            reject(e)
        }
    })
}

const instrumentSearchSuggestions = async (term) => {
    return new Promise(async (resolve, reject) => {
        try{
            const params = "/instruments/search/" + term
            const data = await fetchAppAPI(params)
            resolve(data.map((suggestion) => {
                return ({
                    ...suggestion,
                    maturity: new Date(suggestion.maturity)
                })
            }))
        }catch (e){
            reject(e)
        }
    })
}

const getAllInstruments = async (lastRetrievedIndex, limit, retrieveAll, searchTerm, sortParam, descSort) => {
    return new Promise(async (resolve, reject) => {
        try{
            const params = "/instruments/all"
            const query = `lastRetrievedIndex=${lastRetrievedIndex}&limit=${limit}&retrieveAll=${retrieveAll}&searchTerm=${searchTerm}&sortParam=${sortParam}&descSort=${descSort}`
            const data = await fetchAppAPI(params, query)
            resolve(data.map((instrument) => {
                return ({
                    ...instrument,
                    maturity: new Date(instrument.maturity),
                    firstSessionDate: new Date(instrument.firstSessionDate),
                    lastSessionDate: new Date(instrument.lastSessionDate),
                })
            }))
        }catch (e){
            reject(e)
        }
    })
}


export{
    getFeaturedInstruments,
    getInstrumentDetail,
    instrumentSearchSuggestions,
    getAllInstruments,
}