import {fetchAppAPI} from "../../util/APIUtil";
import {getAPIDateString} from "../../util/dateUtil";
import {normalizeDataPointValueUtil} from "../../util/normalizationUtil";

const getInitialHistoricPricePoints = async (isin, lastSessionDate, initialTargetOffset) => {
    return new Promise(async (resolve, reject) => {
        try {
            const initialTargetLimitDate = getAPIDateString(new Date(lastSessionDate.setDate(lastSessionDate.getDate() - initialTargetOffset)))
            const params = "/historic/initial/" + isin
            const query = `initialTargetLimitDate=${initialTargetLimitDate}`
            const data = await fetchAppAPI(params, query)
            resolve(data.map((point) => {
                return {
                    price: normalizeDataPointValueUtil(point.price),
                    yield: normalizeDataPointValueUtil(point.yield),
                    sessionDate: new Date(point.sessionDate)
                }
            }))
        }catch (e) {
            reject(e)
        }
    })
}

const getRangedHistoricPricePoints = async (isin, targetLimitDate, currentLimitDate) => {
    return new Promise(async (resolve, reject) => {
        try {
            const params = "/historic/ranged/" + isin
            const query = `targetLimitDate=${getAPIDateString(targetLimitDate)}&currentLimitDate=${getAPIDateString(currentLimitDate)}`
            const data = await fetchAppAPI(params, query)
            resolve(data.map((point) => {
                return {
                    price: normalizeDataPointValueUtil(point.price),
                    yield: normalizeDataPointValueUtil(point.yield),
                    sessionDate: new Date(point.sessionDate)
                }
            }))
        }catch (e) {
            reject(e)
        }
    })
}

const getComplementaryHistory = async (isin) => {
    return new Promise(async (resolve, reject) => {
        try {
            const params = "/historic/complementary/" + isin
            const data = await fetchAppAPI(params)
            resolve({
                ...data,
                lastYearsPriceYieldLimits: data.lastYearsPriceYieldLimits.length ? {
                    lower: {
                        sessionDate: new Date(data.lastYearsPriceYieldLimits[1].sessionDate),
                        price: normalizeDataPointValueUtil(data.lastYearsPriceYieldLimits[1].price),
                        yield: normalizeDataPointValueUtil(data.lastYearsPriceYieldLimits[1].yield),
                    },
                    higher: {
                        sessionDate: new Date(data.lastYearsPriceYieldLimits[0].sessionDate),
                        price: normalizeDataPointValueUtil(data.lastYearsPriceYieldLimits[0].price),
                        yield: normalizeDataPointValueUtil(data.lastYearsPriceYieldLimits[0].yield),
                    }
                } : null,
                absolutePriceYieldLimits: data.absolutePriceYieldLimits.length ? {
                    lower: {
                        sessionDate: new Date(data.absolutePriceYieldLimits[1].sessionDate),
                        price: normalizeDataPointValueUtil(data.absolutePriceYieldLimits[1].price),
                        yield: normalizeDataPointValueUtil(data.absolutePriceYieldLimits[1].yield),
                    },
                    higher: {
                        sessionDate: new Date(data.absolutePriceYieldLimits[0].sessionDate),
                        price: normalizeDataPointValueUtil(data.absolutePriceYieldLimits[0].price),
                        yield: normalizeDataPointValueUtil(data.absolutePriceYieldLimits[0].yield),
                    }
                } : null,
            })
        }catch (e) {
            reject(e)
        }
    })
}

export {
    getInitialHistoricPricePoints,
    getComplementaryHistory,
    getRangedHistoricPricePoints
}