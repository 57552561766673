import { createContext } from 'react';

const Context = createContext({
    snackbarSignal:{
        message:'',
        show:false,
        status:'success',
    },

    isLoading: true,
    setIsLoading: (status) => {},

    prevLoc: "",
    setPrevLoc: () => {},

    showSnackbar: (message, status) => {},
    hideSnackbar: () => {},

})

export default Context