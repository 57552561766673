import React from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { grey } from '@mui/material/colors';
import {Box} from "@mui/material";

const TendencyIcon = ({tendency, colorful=true}) => {
    return (
        <Box alignItem>
            {
                tendency === "up" ?
                    <KeyboardArrowUpIcon sx={{color: colorful ? "success.main" : "white"}}/>
                :
                    tendency === "down" ?
                        <KeyboardArrowDownIcon sx={{color: colorful ? "error.main" : "white"}}/>
                    :
                        <KeyboardArrowRightIcon sx={{color: colorful ? grey[600] : "white"}}/>
            }
        </Box>
    );
};

export default TendencyIcon;