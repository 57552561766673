import React, {useEffect, useState} from 'react';
import {Button, Stack} from "@mui/material";
import logo from "../assets/main_logo.png";
import {useNavigate} from "react-router-dom";

const Footer = () => {

    const navigate = useNavigate()

    const [footerItems, setFooterItems] = useState([])

    useEffect(() => {
        setFooterItems([
            {
                label:'Explore Bonds',
                handler: () => navigate('/bonds')
            },
            // {
            //     label:'Indicators & Indexes',
            //     handler: () => navigate('/indicators-indexes')
            // },
            // {
            //     label:'Portfolio calculator',
            //     handler: () => navigate('/portfolio-calculator')
            // },
            {
                label:'Legal disclaimer',
                handler: () => navigate('/legal-disclaimer')
            },
        ])
    },[])

    return (
        <Stack
            direction={{xs: 'column', lg: 'row'}}
            sx={{pb:5,pt:10}}
            gap={4}
        >
            <img style={{width: 125, alignSelf: 'center'}} src={logo} alt='Inverso logo'/>
            <Stack
                direction={{xs: 'column', lg: 'row'}}
                justifyContent="space-between"
                gap={2}
            >
                {footerItems.map(item => {
                    return (
                        <Button onClick={item.handler}>{item.label}</Button>
                    );
                })}
                <Button
                    href="https://github.com/andresmg07"
                    target="_blank"
                    rel="noopener noreferrer"
                >Who developed this app?</Button>
            </Stack>
        </Stack>
    );
};

export default Footer;