import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Provider from "./context/Provider";
import {CssBaseline, ThemeProvider} from "@mui/material";
import theme from "./theme"
import "./index.css"

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Provider>
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <App/>
        </ThemeProvider>
    </Provider>
);

