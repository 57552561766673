import React from 'react';
import {Stack, Typography} from "@mui/material";

const NoResults = () => {
    return (
        <Stack sx={{my:10, display:'flex', alignItems:'center'}}>
            <Typography varian='h4' fontWeight='bold' color='primary.main'>No results were found.</Typography>
            <Typography textAlign='center'>Please try with a different search term and filter combination.</Typography>
        </Stack>
    );
};

export default NoResults;