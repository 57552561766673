import React from 'react';
import {Outlet} from "react-router-dom";
import SnackBarMessage from "../components/SnackBarMessage";
import MainAppBar from "../components/appBar/MainAppBar";
import {Box} from "@mui/material";
import Footer from "../components/Footer";

const RouteWrapper = () => {
    return (
            <div>
                <Box sx={{px: {lg:20, md:10, xs:5}}}>
                    <MainAppBar/>
                    <Outlet/>
                    <SnackBarMessage/>
                    <Footer/>
                </Box>
            </div>
    );
};

export default RouteWrapper;