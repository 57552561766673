import {Card, CardActionArea, CardContent, Stack, Typography} from "@mui/material";
import {dateFormatter} from "../../util/formatterUtil";
import CurrencyIndicator from "../CurrencyIndicator";
import {useNavigate} from "react-router-dom";
import BondStatusChip from "../BondStatusChip";
import React from "react";

const DetailedBondCard = ({bond}) => {

    const navigate = useNavigate()

    return (
        <Card>
            <CardActionArea onClick={() => navigate('/bond/' + bond.isin)}>
                <CardContent>
                    <Stack gap={2}>
                        <Stack>
                            <Typography sx={{ fontWeight: 'bold' }}>{bond.isin}</Typography>
                            <Typography>{bond.country}</Typography>
                        </Stack>
                        <Stack direction='row' gap={1}>
                            {bond && <BondStatusChip status={bond.status} maturity={bond.maturity} chipSize={'small'}/>}
                            <CurrencyIndicator currency={bond.currency}/>
                        </Stack>
                        <Stack direction='row' gap={12}>
                            <Stack>
                                <Typography fontWeight='bold'>Issuer: <Typography>{bond.issuer}</Typography></Typography>
                                <Typography fontWeight='bold'>Price: <Typography>{bond.price.toFixed(2)}%</Typography></Typography>
                            </Stack>
                            <Stack>
                                <Typography fontWeight='bold'>Maturity: <Typography>{dateFormatter(bond.maturity, 'short')}</Typography></Typography>
                                <Typography fontWeight='bold'>Rate: <Typography>{bond.rate.toFixed(2)}%</Typography></Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default DetailedBondCard;