import React from 'react';
import {Stack, List, ListItem, ListItemButton, ListItemText, Button, IconButton, Drawer} from "@mui/material";
import Divider from "@mui/material/Divider";
import logo from "../../assets/main_logo.png";
import {Link} from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

const AppBarDrawer = ({drawerToggle, drawerToggleHandler, items, navigate, window}) => {

    const container = window !== undefined ? () => window().document.body : undefined;

    const onClickHandler = (buttonHandler) => {
        buttonHandler();
        drawerToggleHandler();
    };

    return (
        <nav>
            <Drawer
                container={container}
                variant="temporary"
                open={drawerToggle}
                anchor="right"
                onClose={drawerToggleHandler}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: "display", xl: "none" },
                    "& .MuiDrawer-paper": { boxSizing: "border-box", width: "100%" },
                }}
            >
                <Stack gap={2} sx={{ px: 5, py:3}}>
                    <Stack gap={2} >
                        <Stack direction="row" justifyContent="space-between" alignItem="center">
                            <Link onClick={() => onClickHandler(navigate("/"))}>
                                <img style={{width: 125}} src={logo} alt='Inverso logo'/>
                            </Link>
                            <IconButton color="primary" disableRipple={true} onClick={drawerToggleHandler}>
                                <CloseIcon />
                            </IconButton>
                        </Stack>
                        <Divider />
                    </Stack>
                    <List>
                        {items.length > 0 &&
                            items.map((item) => (
                                <ListItem key={item.label} disablePadding>
                                    <ListItemButton onClick={() => onClickHandler(item.handler)}>
                                        <Stack direction="row" alignItems="center" gap={2}>
                                            {item.icon ? item.icon : null}
                                            <ListItemText primary={item.label} />
                                        </Stack>
                                    </ListItemButton>
                                </ListItem>
                            ))}
                    </List>
                    {items.length > 0 && <Divider />}
                    <List>
                        <ListItem>
                            <Button
                                href="https://github.com/andresmg07"
                                target="_blank"
                                rel="noopener noreferrer"
                                variant="contained"
                                sx={{flex:1, textAlign: "center"}}
                            >WHO DEVELOPED THIS APP?</Button>
                        </ListItem>
                    </List>
                </Stack>
            </Drawer>
        </nav>
    );
};

export default AppBarDrawer;