import React from 'react';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {dateFormatter} from "../../../util/formatterUtil";
import BondStatusChip from "../../../components/BondStatusChip";
import {useNavigate} from "react-router-dom";

const BondsTable = ({bonds}) => {

    const navigate = useNavigate();

    return (
        <TableContainer
            component={Paper}
        >
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>ISIN</TableCell>
                        <TableCell align="right">Issuer</TableCell>
                        <TableCell align="right">Country</TableCell>
                        <TableCell align="right">Maturity</TableCell>
                        <TableCell align="right">Currency</TableCell>
                        <TableCell align="right">Coupon</TableCell>
                        <TableCell align="right">Price</TableCell>
                        <TableCell align="right">Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {bonds.map((row) => (
                        <TableRow
                            key={row.isin}
                            sx={{
                                '&:last-child td, &:last-child th': { border: 0 },
                                "&:hover:not(.Mui-disabled)": {
                                    cursor: "pointer"
                                } }}
                            onClick={() => navigate('/bond/' + row.isin)}
                            hover={true}
                        >
                            <TableCell component="th" scope="row">
                                {row.isin}
                            </TableCell>
                            <TableCell align="right">{row.issuer}</TableCell>
                            <TableCell align="right">{row.country}</TableCell>
                            <TableCell align="right">{dateFormatter(row.maturity, 'short')}</TableCell>
                            <TableCell align="right">{row.currency}</TableCell>
                            <TableCell align="right">{row.rate.toFixed(2)}%</TableCell>
                            <TableCell align="right">{row.price.toFixed(2)}%</TableCell>
                            <TableCell align="right"><BondStatusChip status={row.status} maturity={row.maturity} chipSize={'small'}/></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default BondsTable;