import React from 'react';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Stack,
    useMediaQuery
} from "@mui/material";
import {bondNameFormatter, dateFormatter} from "../../util/formatterUtil";
import {useNavigate} from "react-router-dom";
import CompoundSubHeader from "../../routes/dashboard/components/CompoundSubHeader";

const FeaturedBondTable = ({bonds, featureSubheader, featureCaption, flex}) => {

    const navigate = useNavigate();

    const upMDScreenSize = useMediaQuery((theme) => theme.breakpoints.up('md'));

    return (
        <Paper sx={{flex:flex, p:2}}>
            <Stack gap={2}>
                <CompoundSubHeader subheader={featureSubheader} caption={featureCaption}/>
                <TableContainer>
                    <Table aria-label="simple table" size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Bond</TableCell>
                                {upMDScreenSize && <TableCell align="right">Maturity</TableCell>}
                                {upMDScreenSize && <TableCell align="right">Coupon</TableCell>}
                                <TableCell align="right">Price</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {bonds.map((row) => (
                                <TableRow
                                    key={row.isin}
                                    sx={{
                                        '&:last-child td, &:last-child th': { border: 0 },
                                        "&:hover:not(.Mui-disabled)": {
                                            cursor: "pointer"
                                        } }}
                                    onClick={() => navigate('/bond/' + row.isin)}
                                    hover={true}
                                >
                                    <TableCell component="th" scope="row" sx={{color:"primary.main"}}>
                                        {bondNameFormatter(row)}
                                    </TableCell>
                                    {upMDScreenSize && <TableCell align="right">{dateFormatter(row.maturity, 'short')}</TableCell>}
                                    {upMDScreenSize && <TableCell align="right">{row.rate.toFixed(2)}%</TableCell>}
                                    <TableCell align="right">{row.price.toFixed(2)}%</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Stack>
        </Paper>

    );
};

export default FeaturedBondTable;