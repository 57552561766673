import React from 'react';
import {Stack, Typography, useMediaQuery} from "@mui/material";

const CompoundHeader = ({title, subtitle}) => {
    const upLGScreenSize = useMediaQuery((theme) => theme.breakpoints.up('lg'));

    return (
        <Stack>
            <Typography variant={upLGScreenSize ? 'h3' : 'h4'} sx={{fontWeight: 'bold'}}>{title}</Typography>
            {subtitle && <Typography variant={upLGScreenSize ? 'h5' : 'p'}>{subtitle}</Typography>}
        </Stack>
    );
};

export default CompoundHeader;