import React from 'react';
import {ToggleButton, ToggleButtonGroup, Typography} from "@mui/material";

const ChartRangeToggleGroup = ({value, changeHandler, options}) => {
    return (
        <ToggleButtonGroup
            value={value}
            exclusive
            onChange={changeHandler}
        >
            {
                options.map((option) => {
                    return(
                        <ToggleButton value={option.value}>
                            <Typography fontSize={{xs:"0.75em", md: "0.9em", lg:"1em"}}>{option.label}</Typography>
                        </ToggleButton>
                    );
                })
            }
        </ToggleButtonGroup>
    );
};

export default ChartRangeToggleGroup;