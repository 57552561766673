import React, {useState} from 'react';
import Context from './Context'

const Provider = ({ children }) => {

    const [snackbarSignal, setSnackbarSignal] = useState({
        message:'',
        show:false,
        status:'success',
    });

    const [isLoading, setIsLoading] = useState(true)
    const [prevLoc, setPrevLoc] = useState("")
    const showSnackbar = (message, status) =>{
        setSnackbarSignal({
            message,
            show:true,
            status,
        })
    };

    const hideSnackbar = () =>{
        setSnackbarSignal({
            ...snackbarSignal,
            show:false,
        })
    };


    return (
        <Context.Provider
            value={{
                snackbarSignal,
                showSnackbar,
                hideSnackbar,
                isLoading,
                setIsLoading,
                prevLoc,
                setPrevLoc,
            }}
        >
            {children}
        </Context.Provider>
    );

}

export default Provider;