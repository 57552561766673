import React from 'react';
import {Stack} from "@mui/material";
import CustomLineChart from "../../../components/charts/CustomLineChart";
import {getFormattedPlottingData} from "../../../util/chartUtil";
import ChartRangeToggleGroup from "../../../components/charts/ChartRangeToggleGroup";
import DataPointDelta from "../../../components/DataPointDelta";
import {capitalizeFirstLetterFormatter} from "../../../util/formatterUtil";

const HistoricChart = ({plottingHistoricPoints, plottingRange, handlePlottingRangeChangeHandler, chartRangeToggleOptions, plottingType, handlePlottingTypeChangeHandler, chartTypeToggleOptions}) => {

    const formattedPlottingData = getFormattedPlottingData(plottingType, plottingHistoricPoints)

    return (
        <Stack gap={2} sx={{flex:1}}>
            <Stack direction="row" gap={{xs:4}} alignItems="center" justifyContent="space-between">
                {
                    chartRangeToggleOptions.length && plottingType !== "" && formattedPlottingData &&
                    <DataPointDelta
                        label={capitalizeFirstLetterFormatter(plottingType)}
                        currentValue={formattedPlottingData.datasets[0].data.at(-1)}
                        referenceValue={formattedPlottingData.datasets[0].data.at(0)}
                        referenceTimeRange={chartRangeToggleOptions.filter(option => option.value === plottingRange)[0].label}
                    />
                }
                <ChartRangeToggleGroup
                    value={plottingType}
                    changeHandler={handlePlottingTypeChangeHandler}
                    options={chartTypeToggleOptions}
                />
            </Stack>
            <Stack gap={4} alignItems="center">
                <CustomLineChart chartData={formattedPlottingData}/>
                <ChartRangeToggleGroup
                    value={plottingRange}
                    changeHandler={handlePlottingRangeChangeHandler}
                    options={chartRangeToggleOptions}
                />
            </Stack>
        </Stack>
    );
};

export default HistoricChart;