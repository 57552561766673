import {fetchProxyAPI} from "../../util/APIUtil";
import {getAPIDateString} from "../../util/dateUtil";

const getCurrentDollarColonExchangeRateWithReference = (referenceOffset) => {
    return new Promise(async (resolve, reject) => {
        try{
            const purchaseParams = "/indicator/reference/current/dollar-colon-purchase-exchange-rate"
            const sellParams = "/indicator/reference/current/dollar-colon-sell-exchange-rate"
            const query = "offset=" + referenceOffset
            const purchaseData = await fetchProxyAPI(purchaseParams, query)
            const sellData = await fetchProxyAPI(sellParams, query)
            resolve({purchase: purchaseData, sell: sellData})
        }catch (e) {
            reject(e)
        }
    })
}

const getCurrentDollarColonAverageExchangeRateWithReference = (referenceOffset) => {
    return new Promise(async (resolve, reject) => {
        try{
            const purchaseParams = "/indicator/reference/current/dollar-colon-purchase-exchange-rate"
            const sellParams = "/indicator/reference/current/dollar-colon-sell-exchange-rate"
            const query = "offset=" + referenceOffset
            const purchaseData = await fetchProxyAPI(purchaseParams, query)
            const sellData = await fetchProxyAPI(sellParams, query)
            resolve({
                delta: (purchaseData.delta + sellData.delta)/2,
                target: {
                    value: (purchaseData.target.value + sellData.target.value) / 2,
                    date: purchaseData.target.date
                },
                offset:{
                    value:(purchaseData.offset.value + sellData.offset.value)/2,
                    date: purchaseData.offset.date
                }
            })
        }catch (e) {
            reject(e)
        }
    })
}

const getCurrentTPMWithReference = (referenceOffset) => {
    return new Promise(async (resolve, reject) => {
        try{
            const params = "/indicator/reference/current/tpm"
            const query = "offset=" + referenceOffset
            const data = await fetchProxyAPI(params, query)
            resolve(data)
        }catch (e) {
            reject(e)
        }
    })
}

const getCurrentTBPWithReference = (referenceOffset) => {
    return new Promise(async (resolve, reject) => {
        try{
            const params = "/indicator/reference/current/tbp"
            const query = "offset=" + referenceOffset
            const data = await fetchProxyAPI(params, query)
            resolve(data)
        }catch (e) {
            reject(e)
        }
    })
}

const getCurrentTEDWithReference = (referenceOffset) => {
    return new Promise(async (resolve, reject) => {
        try{
            const params = "/indicator/reference/current/ted"
            const query = "offset=" + referenceOffset
            const data = await fetchProxyAPI(params, query)
            resolve(data)
        }catch (e) {
            reject(e)
        }
    })
}

const getCurrentMONEXExchangeRateAverageWithReference = (referenceOffset) => {
    return new Promise(async (resolve, reject) => {
        try{
            const params = "/indicator/reference/current/monex-exchange-rate"
            const query = "offset=" + referenceOffset
            const data = await fetchProxyAPI(params, query)
            resolve(data)
        }catch (e) {
            reject(e)
        }
    })
}

const getHistoricDollarColonExchangeRate = (startDate, endDate) => {
    return new Promise(async (resolve, reject) => {
        try{
            const purchaseParams = "/indicator/range/dollar-colon-purchase-exchange-rate"
            const sellParams = "/indicator/range/dollar-colon-sell-exchange-rate"
            const query = "startDate=" + getAPIDateString(startDate) + "&endDate=" + getAPIDateString(endDate)
            const purchaseData = await fetchProxyAPI(purchaseParams, query)
            const sellData = await fetchProxyAPI(sellParams, query)
            resolve({purchase: purchaseData, sell: sellData})
        }catch (e) {
            reject(e)
        }
    })
}

const getHistoricTPM = (startDate, endDate) => {
    return new Promise(async (resolve, reject) => {
        try{
            const params = "/indicator/range/tpm"
            const query = "startDate=" + getAPIDateString(startDate) + "&endDate=" + getAPIDateString(endDate)
            const data = await fetchProxyAPI(params, query)
            resolve(data.map( (point) => {
                return {
                    ...point,
                    date: new Date(point.date)
                }
            }))
        }catch (e) {
            reject(e)
        }
    })
}

const getHistoricTBP = (startDate, endDate) => {
    return new Promise(async (resolve, reject) => {
        try{
            const params = "/indicator/range/tbp"
            const query = "startDate=" + getAPIDateString(startDate) + "&endDate=" + getAPIDateString(endDate)
            const data = await fetchProxyAPI(params, query)
            resolve(data.map( (point) => {
                return {
                    ...point,
                    date: new Date(point.date)
                }
            }))
        }catch (e) {
            reject(e)
        }
    })
}

const getHistoricTED = (startDate, endDate) => {
    return new Promise(async (resolve, reject) => {
        try{
            const params = "/indicator/range/ted"
            const query = "startDate=" + getAPIDateString(startDate) + "&endDate=" + getAPIDateString(endDate)
            const data = await fetchProxyAPI(params, query)
            resolve(data.map( (point) => {
                return {
                    ...point,
                    date: new Date(point.date)
                }
            }))
        }catch (e) {
            reject(e)
        }
    })
}

const getHistoricMONEXExchangeRateAverage = (startDate, endDate) => {
    return new Promise(async (resolve, reject) => {
        try{
            const params = "/indicator/range/monex-exchange-rate"
            const query = "startDate=" + getAPIDateString(startDate) + "&endDate=" + getAPIDateString(endDate)
            const data = await fetchProxyAPI(params, query)
            resolve(data.filter(point => point.value !== 0).map( (point) => {
                return {
                    ...point,
                    date: new Date(point.date)
                }
            }))
        }catch (e) {
            reject(e)
        }
    })
}

const getHistoricDollarColonAverageExchangeRate = (startDate, endDate) => {
    return new Promise(async (resolve, reject) => {
        try{
            const purchaseParams = "/indicator/range/dollar-colon-purchase-exchange-rate"
            const sellParams = "/indicator/range/dollar-colon-sell-exchange-rate"
            const query = "startDate=" + getAPIDateString(startDate) + "&endDate=" + getAPIDateString(endDate)
            const purchaseData = await fetchProxyAPI(purchaseParams, query)
            const sellData = await fetchProxyAPI(sellParams, query)

            resolve(purchaseData.map((purchaseRate, idx) => {
                return {
                    value: (purchaseRate.value + sellData[idx].value) / 2,
                    date: new Date(purchaseRate.date)
                }
            }))
        }catch (e) {
            reject(e)
        }
    })
}

export {
    getCurrentDollarColonExchangeRateWithReference,
    getCurrentDollarColonAverageExchangeRateWithReference,
    getCurrentTPMWithReference,
    getCurrentTBPWithReference,
    getCurrentTEDWithReference,
    getCurrentMONEXExchangeRateAverageWithReference,
    getHistoricDollarColonAverageExchangeRate,
    getHistoricDollarColonExchangeRate,
    getHistoricTPM,
    getHistoricTBP,
    getHistoricTED,
    getHistoricMONEXExchangeRateAverage,
}