import React from 'react';
import {Stack, Button, Paper, Typography, Box} from "@mui/material";
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const DataCard = ({title, data, flex, seeMoreHandler=null}) => {
    return (
        data &&
            <Box sx={{flex:flex,}}>
                <Paper sx={{p:2, height:"100%"}}>
                    <Stack direction={{xs: "column", lg: "row"}} alignItems={{xs: "flex-start", lg:"center"}} justifyContent={{xs: "flex-start", lg: "space-between"}} >
                        <Typography variant="subtitle1" fontWeight="bold">{title}</Typography>
                        {seeMoreHandler && <Button onClick={seeMoreHandler} endIcon={<KeyboardArrowRightIcon />}>See more</Button>}
                    </Stack>
                    <List spacing={2}>
                        {
                            data.length > 0 && data.map((point, index) => {
                                return (
                                    <div>
                                        <ListItemText primary={point.value} secondary={point.label}/>
                                        {index < data.length - 1 && <Divider/>}
                                    </div>
                                );
                            })
                        }
                    </List>
                </Paper>
            </Box>

    );
};

export default DataCard;