import {fetchCountryAPI, fetchWorldBankAPI} from "../../util/APIUtil";

const getCountryDataRequest  = async (countryName) => {
    const countryDataParams = "/v3.1/alpha/" + encodeURIComponent(countryName)
    const countryDataQuery = "fields=name,capital,region,subregion,independent,currencies,borders,languages,area,population,flag"
    const countryData = await fetchCountryAPI(countryDataParams, countryDataQuery)
    let borderCountryNames = []
    if(countryData.borders){
        for (const code of countryData.borders) {
            const borderCountryNamesParams = `/v3.1/alpha/${code}`
            const borderCountryNamesQuery = "fields=name"
            borderCountryNames.push((await fetchCountryAPI(borderCountryNamesParams, borderCountryNamesQuery)).name.common)
        }
    }

    return {
        officialName: countryData.name.official,
        capital: countryData.capital,
        region: countryData.region,
        subregion: countryData.subregion,
        isIndependent: countryData.independent,
        currencies: Object.keys(countryData.currencies).map(key => countryData.currencies[key].name),
        languages: Object.keys(countryData.languages).map(key => countryData.languages[key]),
        borders: borderCountryNames,
        area: countryData.area,
        population: countryData.population,
        flag: countryData.flag
    }
}

const getBasicCountryDataRequest  = async (countryName) => {
    const basicDataParams = "/v3.1/alpha/" + encodeURIComponent(countryName)
    const basicDataQuery = "fields=name,capital,region,currencies,area,population"
    const basicCountryData = await fetchCountryAPI(basicDataParams, basicDataQuery)

    return {
        officialName: basicCountryData.name.official,
        capital: basicCountryData.capital,
        region: basicCountryData.region,
        currencies: Object.keys(basicCountryData.currencies).map(key => basicCountryData.currencies[key].name),
        area: basicCountryData.area,
        population: basicCountryData.population
    }
}

const getBasicEconomicAndGrowthIndicatorsRequest = async (countryCode) => {
    const indicators = ["NY.GDP.MKTP.CD", "NY.GDP.MKTP.KD.ZG"]

    const basicEconomicAndGrowthParams = `/country/${countryCode}/indicator/${indicators.join(";")}/`
    const basicEconomicAndGrowthQuery = "source=2&format=json&mrv=1"

    const basicEconomicAndGrowthData = await fetchWorldBankAPI(basicEconomicAndGrowthParams, basicEconomicAndGrowthQuery)

    return {
        gdp: {label: basicEconomicAndGrowthData[1][0].indicator.value, value: basicEconomicAndGrowthData[1][0].value},
        gdpGrowth: {label: basicEconomicAndGrowthData[1][1].indicator.value, value: basicEconomicAndGrowthData[1][1].value},
    }
}


const getEconomicAndGrowthIndicatorsRequest = async (countryCode) => {
    const indicators = ["NY.GDP.MKTP.CD", "NY.GDP.PCAP.CD", "NY.GDP.MKTP.KD.ZG", "NE.EXP.GNFS.ZS", "NE.IMP.GNFS.ZS", "BN.CAB.XOKA.GD.ZS" ,"NE.GDI.TOTL.ZS"]

    const economicAndGrowthParams = `/country/${countryCode}/indicator/${indicators.join(";")}/`
    const economicAndGrowthQuery = "source=2&format=json&mrv=1"

    const economicAndGrowthData = await fetchWorldBankAPI(economicAndGrowthParams, economicAndGrowthQuery)

    return {
        gdp: {label: economicAndGrowthData[1][0].indicator.value, value: economicAndGrowthData[1][0].value},
        gdpPerCapita: {label: economicAndGrowthData[1][1].indicator.value, value: economicAndGrowthData[1][1].value},
        gdpGrowth: {label: economicAndGrowthData[1][2].indicator.value, value: economicAndGrowthData[1][2].value},
        gdpExports: {label: economicAndGrowthData[1][3].indicator.value, value: economicAndGrowthData[1][3].value},
        gdpImports: {label: economicAndGrowthData[1][4].indicator.value, value: economicAndGrowthData[1][4].value},
        accountBalance: {label: economicAndGrowthData[1][5].indicator.value, value: economicAndGrowthData[1][5].value},
        grossCapitalFormation: {label: economicAndGrowthData[1][6].indicator.value, value: economicAndGrowthData[1][6].value},
    }
}

export {
    getCountryDataRequest,
    getBasicCountryDataRequest,
    getEconomicAndGrowthIndicatorsRequest,
    getBasicEconomicAndGrowthIndicatorsRequest,
}